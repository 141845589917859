import React, { useEffect, useState } from "react";
import APIServices from "../../../service/APIService";
import { API } from "../../../components/constants/api_url";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import $ from "jquery";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import FileSaver from "file-saver";
import { InputTextarea } from "primereact/inputtextarea";
import { TabMenu } from "primereact/tabmenu";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { MultiSelect } from "primereact/multiselect";
import { ListBox } from "primereact/listbox";
import { Checkbox } from "primereact/checkbox";
import { DateTime } from "luxon";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";

const AssignDcfToUser = () => {
    const [label1,label2,label3] = useSelector((state) => state.user.tierLabel);
    const [dcfentityass, setDcfEntityAss] = useState([])
    const [dfentityass, setDfEntityAss] = useState([])
    const [srfentityass, setSrfEntityAss] = useState([])
    const [rfresponse, setRFResponse] = useState([])
    const [load, setLoading] = useState(true)
    const [rawrf, setRawRF] = useState([])
    const [rawsrf, setRawSRF] = useState([])
    const [rfentityass, setRFEntityAss] = useState([])
    const [minDate, setMinDate] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0);
    const [dcfentityuserass, setDcfEntityUserAss] = useState([])
    const [dfentityuserass, setDfEntityUserAss] = useState([])
    const [srfentityuserass, setSrfEntityUserAss] = useState([])
    const [frequencyMonths, setFrequencyMonths] = useState([])
    const [stdlist, setStdList] = useState([])
    const [stdlistBk, setStdListBK] = useState([])
    const [selectedloclist, setSelectedLocList] = useState({ tier1: [], tier2: [], tier3: [] })
    const [assigneddcflist, setAssignedDcfList] = useState([])
    const [assignedsrflist, setAssignedSrfList] = useState([])
    const [requiredList, setRequiredList] = useState([])
    const [requiredListBK, setRequiredListBK] = useState([])
    const [requiredList2, setRequiredList2] = useState([])
    const [assFramework, setAssFramework] = useState([])
    const [selectedFramework2, setSelectedFramework2] = useState([])
    const [assFramework2, setAssFramework2] = useState([])
    const [selectedFramework, setSelectedFramework] = useState([])


    const [requiredList2BK, setRequiredList2BK] = useState([])
    const [selecteddcfentity, setSelectedDcfEntity] = useState({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], title: null, dcfId: null })
    const [selectedsrfentity, setSelectedSrfEntity] = useState({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], title: null, srfId: null })
    const [selecteddfentity, setSelectedDfEntity] = useState({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], title: null, dfId: null, type: null })
    const [dcfentityuserobj, setDcfEntityUserObj] = useState({ frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [], standard: null })
    const [allentityuserobj, setAllEntityUserObj] = useState({ frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [], standard: null })
    const [srfentityuserobj, setSrfEntityUserObj] = useState({ frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [] })
    const [dfentityuserobj, setDfEntityUserObj] = useState({ frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [], type: null })
    const [dcfentitydialog, setDcfEntityDialog] = useState(false)
    const [srfentitydialog, setSrfEntityDialog] = useState(false)
    const [dfentitydialog, setDfEntityDialog] = useState(false)
    const [dcfentityuserdialog, setDcfEntityUserDialog] = useState(false)
    const [dfentityuserdialog, setDfEntityUserDialog] = useState(false)
    const [srfentityuserdialog, setSrfEntityUserDialog] = useState(false)
    const [allentityuserdialog, setAllEntityUserDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [dcfentityviewdialog, setDcfEntityViewDialog] = useState(false)
    const [srfentityviewdialog, setSrfEntityViewDialog] = useState(false)
    const [dfenitityviewdialog, setDfEnitityViewDialog] = useState(false)
    const [rawsitelist, setRawSitelist] = useState([])
    const [userList, setUserList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [approverList, setApproverList] = useState([]);
    const [reviewerList, setReviewerList] = useState([]);
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]
    const selector = useSelector((state) => state.user.userdetail);
    useEffect(() => {
        let uriString = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

        }
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }


        const promise0 = APIServices.get(API.AssignDCFClient_UP(selector.id))

        const promise1 = APIServices.get(API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise2 = APIServices.get(API.DCF)
        const promise3 = APIServices.get(API.LocationOne_UP(selector.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`)
        const promise4 = APIServices.get(API.DCF_Entity_UP(selector.id))
        const promise5 = APIServices.get(API.DCF_Entity_User_UP(selector.id))
        const promise6 = APIServices.get(API.UserProfile)
        const promise7 = APIServices.get(API.EF_Std)
        const promise8 = APIServices.get(API.RF)
        const promise9 = APIServices.get(API.RF_Submit_UP(selector.id))
        const promise10 = APIServices.get(API.Report_Name_Twos)
        const promise11 = APIServices.get(API.DF_Entity_UP(selector.id))
        const promise12 = APIServices.get(API.DF_Entity_User_UP(selector.id))
        const promise13 = APIServices.get(API.SRF)
        const promise14 = APIServices.get(API.SRF_Entity_UP(selector.id))
        const promise15 = APIServices.get(API.SRF_Entity_User_UP(selector.id))
        Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9, promise10, promise11, promise12, promise13, promise14, promise15]).then((values) => {
            let dcf_list = values[2].data, assinged_dcf = [], assinged_srf = [], assigned_dcf_entity = values[4].data, assinged_rf_entity = values[11].data, assigned_srf_entity = values[14].data
            let tier1 = [], tier2 = [], tier3 = [], approver_list = [{ id: selector.id, name: 'Enterprise Admin' }], reviewer_list = [], reporter_list = [], supplier_list = []
            setStdListBK(values[7].data)
            values[6].data.filter(i => (i.information.cid === selector.id && i.role === 'clientsupplier')).forEach((item) => {
                if (item.information.role !== undefined && item.information.role.reporter) {
                    supplier_list.push({
                        name: item.information.empname,
                        id: item.id,
                    })
                }
            })
            values[6].data.filter(i => (i.information.cid === selector.id && i.role === 'clientuser')).forEach((item) => {
                if (item.information.role !== undefined && item.information.role.reporter) {
                    reporter_list.push({
                        name: item.information.empname,
                        id: item.id,
                    })
                }
                if (item.information.role !== undefined && item.information.role.reviewer) {
                    console.log(item.information.role.reviewer, item.information.empname)
                    reviewer_list.push({
                        name: item.information.empname,
                        id: item.id,
                    })
                }
                if (item.information.role !== undefined && item.information.role.approver) {
                    approver_list.push({
                        name: item.information.empname,
                        id: item.id,
                    })
                }

            })
            // DF

            let required_rf = [], optional_rf = []
            setRawRF(values[8].data)
            setRawSRF(values[13].data)
            setRFResponse(values[9].data)
            // DF
            setApproverList(approver_list)
            setReviewerList(reviewer_list)
            setUserList(reporter_list)
            setSupplierList(supplier_list)
            setDcfEntityAss(assigned_dcf_entity)
            setSrfEntityAss(assigned_srf_entity)
            setDfEntityAss(assinged_rf_entity)
            setDcfEntityUserAss(values[5].data)
            setSrfEntityUserAss(values[15].data)
            setDfEntityUserAss(values[12].data)
            const shapedSite = values[3].data.map(item => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(locationTwo =>
                        locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter(item => item.locationTwos && item.locationTwos.length > 0)
            const shapedCategory = values[1].data.map(item => {
                if (item.newTopics) {
                    item.newTopics = item.newTopics.filter(topics =>
                        topics.newMetrics && topics.newMetrics.length > 0
                    );
                }
                return item;
            }).filter(item => item.newTopics && item.newTopics.length > 0)
            console.log(shapedCategory)
            shapedSite.forEach((i) => {
                tier1.push({ name: i.name, id: i.id })
                i.locationTwos.forEach((j) => {
                    tier2.push({ name: j.name, id: j.id })
                    j.locationThrees.forEach((k) => {
                        tier3.push({ name: k.name, id: k.id })
                    })
                })

            })



            setSelectedLocList({ tier1, tier2, tier3 })
            console.log(shapedCategory.flatMap(i => i.newTopics.flatMap(j => j.newMetrics)))

            setRawSitelist(shapedSite)
            if (values[0].data.length !== 0) {
                // DF
                let topic_ids = values[0].data[0].topic_ids
                if (Array.isArray(values[0].data[0].cf_ids)) {
                    assinged_srf = values[13].data.filter((m) => values[0].data[0].cf_ids.filter(i => values[13].data.map(j => j.id).includes(i)).includes(m.id)).filter(i => (i.tags === null || i.tags.includes(selector.id)))

                    console.log(assinged_srf)
                }

                shapedCategory.forEach((cat) => {
                    if (cat.newTopics) {
                        cat.newTopics.forEach((topic) => {
                            if (topic_ids.includes(topic.id) && (topic.tag === null || parseFloat(topic.tag) === selector.id) && topic.newMetrics) {
                                topic.newMetrics.forEach((metric) => {
                                    if (Array.isArray(metric.data1) && metric.data1[0] !== undefined && metric.data1[0].type === 1 && values[8].data.map(i => i.id).includes(metric.data1[0].rf) && (metric.tag === null || parseFloat(metric.tag) === selector.id)) {
                                   let rf_index = values[8].data.findIndex(i => i.id === metric.data1[0].rf)
                                   
                                   if(rf_index !== -1 && values[8].data[rf_index].tags === null || values[8].data[rf_index].tags.includes(selector.id)) {
                                 
                                        required_rf.push({ cat_id: cat.id, top_id: topic.id, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_title: cat.title, top_title: topic.title, title: metric.title, ...metric })
                                   }
                                    }
                                })
                            } else if (!topic_ids.includes(topic.id) && topic.newMetrics && (topic.tag === null || parseFloat(topic.tag) === selector.id)) {
                                topic.newMetrics.forEach((metric) => {
                                    if (Array.isArray(metric.data1) && metric.data1[0] !== undefined && metric.data1[0].type === 1 && values[8].data.map(i => i.id).includes(metric.data1[0].rf) && (metric.tag === null || parseFloat(metric.tag) === selector.id)) {
                                        let rf_index = values[8].data.findIndex(i => i.id === metric.data1[0].rf)
                                        if( rf_index !== -1 &&  values[8].data[rf_index].tags === null || values[8].data[rf_index].tags.includes(selector.id)) {
                                      
                                        optional_rf.push({ cat_id: cat.id, top_id: topic.id, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_title: cat.title, top_title: topic.title, title: metric.title, ...metric })
                                        }
                                    }
                                })
                            }
                        }
                        )
                    }
                })
                // DF
                console.log(shapedCategory.flatMap(i => i.newTopics))
                shapedCategory.flatMap(i => i.newTopics).forEach((top) => {
                    if (values[0].data[0].topic_ids.includes(top.id) && (top.tag === null || top.tag === selector.id)) {
                        top.newMetrics.forEach((met) => {

                            if (values[0].data[0].metric_ids.includes(met.id) && (met.tag === null || parseFloat(met.tag) === selector.id) && met.newDataPoints !== undefined) {
                                met.newDataPoints.forEach((ndp) => {

                                    if (Array.isArray(ndp.data1) && ndp.data1[0].datasource && typeof ndp.data1[0].datasource === 'number' && dcf_list.map(i => i.id).includes(ndp.data1[0].datasource)) {
                                        let dcf_index = dcf_list.findIndex(i => i.id === ndp.data1[0].datasource)
                                        if (!assinged_dcf.map(i => i.id).includes(ndp.data1[0].datasource) && dcf_index !== -1 && (dcf_list[dcf_index].tags === null || dcf_list[dcf_index].tags.includes(selector.id))) {

                                            assinged_dcf.push(dcf_list[dcf_index])
                                        }
                                    }
                                })
                            }
                        })
                    }
                })
                console.log(assinged_dcf)

                for (const dcfass of assinged_dcf) {
                    let entity_index = assigned_dcf_entity.findIndex(i => i.dcfId === dcfass.id)
                    if (entity_index !== -1) {
                        dcfass.entity = { ...assigned_dcf_entity[entity_index], title: dcfass.title }
                    } else {
                        dcfass.entity = { dcfId: dcfass.id, tier1_ids: [], tier2_ids: [], tier3_ids: [], tier0_ids: [], title: dcfass.title }
                    }
                }
                for (const srfass of assinged_srf) {
                    let entity_index = assigned_srf_entity.findIndex(i => i.srfId === srfass.id)
                    if (entity_index !== -1) {
                        srfass.entity = { ...assigned_srf_entity[entity_index], title: srfass.title }
                    } else {
                        srfass.entity = { srfId: srfass.id, tier1_ids: [], tier2_ids: [], tier3_ids: [], tier0_ids: [], title: srfass.title }
                    }
                }
                for (const dfass of required_rf) {

                    let entity_index = assinged_rf_entity.findIndex(i => i.dfId === dfass.data1[0].rf)
                    if (entity_index !== -1) {
                        dfass.entity = { ...assinged_rf_entity[entity_index], title: dfass.title }
                    } else {
                        dfass.entity = { dfId: dfass.data1[0].rf, tier1_ids: [], tier2_ids: [], tier3_ids: [], tier0_ids: [], title: dfass.title, type: 1 }
                    }
                }

                for (const dfass of optional_rf.filter((i) => { return !required_rf.map(i => i.id).includes(i.id) })) {
                    console.log(dfass)
                    let entity_index = assinged_rf_entity.findIndex(i => i.dfId === dfass.data1[0].rf)
                    if (entity_index !== -1) {
                        dfass.entity = { ...assinged_rf_entity[entity_index], title: dfass.title }
                    } else {
                        dfass.entity = { dfId: dfass.data1[0].rf, tier1_ids: [], tier2_ids: [], tier3_ids: [], tier0_ids: [], title: dfass.title, type: 2 }
                    }
                }
                setAssignedDcfList(assinged_dcf)
                setAssignedSrfList(assinged_srf)

                setAssFramework(values[10].data.filter((i) => { return selector.information.report.includes(i.id) }))
                setAssFramework2(values[10].data)
                setRequiredList2(optional_rf.filter((i) => { return !required_rf.map(i => i.id).includes(i.id) }))
                setRequiredList2BK(optional_rf.filter((i) => { return !required_rf.map(i => i.id).includes(i.id) }))
                setRequiredList(required_rf)
                setRequiredListBK(required_rf)
            }
            setLoading(false)
        })
    }, [])

    const addQNEntityTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center" onClick={() => { setSelectedDcfEntity(rowData.entity); setDcfEntityDialog(true) }}>
                Add
            </div>
        )
    }
    const addSRFEntityTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center" onClick={() => { setSelectedSrfEntity(rowData.entity); setSrfEntityDialog(true) }}>
                Add
            </div>
        )
    }
    const rrTemplate = (rowData) => {


        return (
            < >
                <a  >       {rowData.data1[0].title} </a>

            </>
        )
    }
    const addQLEntityTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center" onClick={() => { setSelectedDfEntity(rowData.entity); setDfEntityDialog(true) }}>
                Add
            </div>
        )
    }
    const titleTemplate = (rowData) => {

        return (
            < >
                <Tooltip className="tag-tooltip" target={".tags" + rowData.id} position={'top'} autoHide={true}> {rowData.overallTags.map((i, j) => {
                    if (i.length !== 0) {
                        return (
                            <>
                                <label style={{ color: 'black', display: 'flex' }}> {
                                    j === 0 ? 'Must Have' : j === 1 ? 'Progressive' : 'Advanced'

                                }
                                </label>
                                {
                                    i.map((tag, k) => {

                                        return (
                                            <label style={{ color: 'green' }}>{tag}{k !== i.length - 1 && ','}</label>
                                        )

                                    })
                                }
                                <div style={{ marginBottom: 10 }} />
                            </>
                        )
                    }
                })} </Tooltip>
                <div style={{ alignItems: 'center' }} >{rowData.title} <i className={"material-icons " + "tags" + rowData.id} style={{ fontSize: 14, cursor: 'pointer' }}>info</i>  </div>

            </>
        )
    }
    const addEntityUserTemplate = (rowData) => {
        return (
            <div onClick={() => { openDCFEntityUserAss(rowData) }}>
                Add
            </div>
        )
    }
    const openSRFEntityUserAss = (rowData) => {
        let rf_index = srfentityuserass.findIndex((i) => { return i.id === rowData.assId })

        if (rf_index === -1) {
            let end_date_index = srfentityuserass.sort((a, b) => { return b.id - a.id }).filter(i => i.srfId === rowData.id && (i.tier3_id === rowData.tier3_id || i.tier3_id === null) && (i.tier2_id === rowData.tier2_id || i.tier2_id === null) && (i.tier1_id === rowData.tier1_id || i.tier1_id === null) && (i.tier0_id === rowData.tier0_id || i.tier0_id === null))

            if (end_date_index.length) {
                setMinDate(end_date_index[0].end_date)
            } else {
                setMinDate(null)
            }


            setSrfEntityUserObj({ [`tier${rowData.level}_id`]: rowData.locationId, level: rowData.level, locationId: rowData.locationId, srfId: rowData.id, frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [] })
        } else {


            let oldObj = { ...srfentityuserass[rf_index] }
            if (typeof oldObj.start_date !== 'object') {
                oldObj.start_date = DateTime.fromISO(oldObj.start_date, { zone: 'utc' }).toLocal().toJSDate()
            }

            if (oldObj.end_date !== undefined && oldObj.end_date !== null && typeof oldObj.end_date !== 'object') {
                oldObj.disable_end = true
                oldObj.end_date = DateTime.fromISO(oldObj.end_date, { zone: 'utc' }).toLocal().toJSDate()
            } else if (oldObj.end_date !== undefined && oldObj.end_date !== null) {
                oldObj.disable_end = true
            }
            console.log(oldObj)
            setSrfEntityUserObj(oldObj)
        }
        setSrfEntityUserDialog(true)
    }
    const openAllEntityUserAss = (rowData) => {
        let oldObj = { ...rowData }
        if (typeof oldObj.start_date !== 'object') {
            oldObj.start_date = DateTime.fromISO(oldObj.start_date, { zone: 'utc' }).toLocal().toJSDate()
        }

        if (oldObj.end_date !== undefined && oldObj.end_date !== null && typeof oldObj.end_date !== 'object') {
            oldObj.disable_end = true
            oldObj.end_date = DateTime.fromISO(oldObj.end_date, { zone: 'utc' }).toLocal().toJSDate()
        } else if (oldObj.end_date !== undefined && oldObj.end_date !== null) {
            oldObj.disable_end = true
        }
        setAllEntityUserObj(oldObj)
        setAllEntityUserDialog(true)
    }
    const openDCFEntityUserAss = (rowData) => {
        let rf_index = dcfentityuserass.findIndex((i) => { return i.id === rowData.assId })
        console.log(rowData)
        if (rf_index === -1) {
            let end_date_index = dcfentityuserass.sort((a, b) => { return b.id - a.id }).filter(i => i.dcfId === rowData.id && (i.tier3_id === rowData.tier3_id || i.tier3_id === null) && (i.tier2_id === rowData.tier2_id || i.tier2_id === null) && (i.tier1_id === rowData.tier1_id || i.tier1_id === null) && (i.tier0_id === rowData.tier0_id || i.tier0_id === null))
            console.log(end_date_index)
            if (end_date_index.length) {

                setMinDate(end_date_index[0].end_date)
            } else {
                setMinDate(null)
            }

            setStdList(stdlistBk.filter((i) => { return i.dcf_ids !== null && i.dcf_ids.includes(rowData.id) }))
            // let newObj = {}
            // if (rowData.tier3_id !== undefined && rowData.tier3_id !== null) {
            //     newObj['tier3_id'] = rowData.tier3_id
            // } else if (rowData.tier2_id !== undefined && rowData.tier2_id !== null) {
            //     newObj['tier2_id'] = rowData.tier2_id
            // } else if (rowData.tier1_id !== undefined && rowData.tier1_id !== null) {
            //     newObj['tier1_id'] = rowData.tier1_id
            // } else if (rowData.tier0_id !== undefined && rowData.tier0_id !== null) {
            //     newObj['tier0_id'] = rowData.tier0_id
            // }

            setDcfEntityUserObj({ [`tier${rowData.level}_id`]: rowData.locationId, level: rowData.level, locationId: rowData.locationId, dcfId: rowData.id, frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [], standard: null })
        } else {

            setStdList(stdlistBk.filter((i) => { return i.dcf_ids !== null && i.dcf_ids.includes(rowData.dcfId) }))
            let oldObj = { ...dcfentityuserass[rf_index] }
            if (typeof oldObj.start_date !== 'object') {
                oldObj.start_date = DateTime.fromISO(oldObj.start_date, { zone: 'utc' }).toLocal().toJSDate()
            }

            if (oldObj.end_date !== undefined && oldObj.end_date !== null && typeof oldObj.end_date !== 'object') {
                oldObj.disable_end = true
                oldObj.end_date = DateTime.fromISO(oldObj.end_date, { zone: 'utc' }).toLocal().toJSDate()
            } else if (oldObj.end_date !== undefined && oldObj.end_date !== null) {
                oldObj.disable_end = true
            }
            console.log(oldObj)
            setDcfEntityUserObj(oldObj)
        }
        setDcfEntityUserDialog(true)
    }
    const openDFEntityUserAss = (rowData) => {
        let rf_index = dfentityuserass.findIndex((i) => { return i.id === rowData.assId })

        if (rf_index === -1) {
            let end_date_index = dfentityuserass.sort((a, b) => { return b.id - a.id }).filter(i => i.dfId === rowData.data1[0].rf && (i.tier3_id === rowData.tier3_id || i.tier3_id === null) && (i.tier2_id === rowData.tier2_id || i.tier2_id === null) && (i.tier1_id === rowData.tier1_id || i.tier1_id === null) && (i.tier0_id === rowData.tier0_id || i.tier0_id === null))

            if (end_date_index.length) {
                setMinDate(end_date_index[0].end_date)
            } else {
                setMinDate(null)
            }

            setDfEntityUserObj({ [`tier${rowData.level}_id`]: rowData.locationId, level: rowData.level, locationId: rowData.locationId, dfId: rowData.data1[0].rf, frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [], type: rowData.type })
        } else {


            let oldObj = { ...dfentityuserass[rf_index] }
            if (typeof oldObj.start_date !== 'object') {
                oldObj.start_date = DateTime.fromISO(oldObj.start_date, { zone: 'utc' }).toLocal().toJSDate()
            }

            if (oldObj.end_date !== undefined && oldObj.end_date !== null && typeof oldObj.end_date !== 'object') {
                oldObj.disable_end = true
                oldObj.end_date = DateTime.fromISO(oldObj.end_date, { zone: 'utc' }).toLocal().toJSDate()
            } else if (oldObj.end_date !== undefined && oldObj.end_date !== null) {
                oldObj.disable_end = true
            }
            console.log(oldObj)
            setDfEntityUserObj(oldObj)
        }
        setDfEntityUserDialog(true)
    }
    const viewDcfEntityTemplate = (rowData) => {
        let index = 0
        let rf_index = dcfentityass.findIndex((i) => { return i.dcfId === rowData.id })
        if (rf_index !== -1) {
            let findIndex = dcfentityass.filter((i) => { return i.dcfId === rowData.id }).length
            if (findIndex) {
                console.log(dcfentityass[rf_index])
                let count = dcfentityass[rf_index].tier2_ids.length + dcfentityass[rf_index].tier3_ids.length + dcfentityass[rf_index].tier1_ids.length + dcfentityass[rf_index].tier0_ids.length
                if (count) {
                    index = count
                }
            }

        }
        return (
            <div className="flex justify-content-center">
                {index !== 0 ?
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                        <label onClick={() => { setDcfEntityViewDialog(true); setSelectedDcfEntity({ ...dcfentityass[rf_index], title: rowData.title }) }} className="text-underline cur-pointer col-12" >view</label>

                    </div> :
                    'NA'

                }
            </div>
        )
    }
    const viewSrfEntityTemplate = (rowData) => {
        let index = 0
        let rf_index = srfentityass.findIndex((i) => { return i.srfId === rowData.id })
        console.log(srfentityass)
        if (rf_index !== -1) {
            let findIndex = srfentityass.filter((i) => { return i.srfId === rowData.id }).length
            if (findIndex) {
                console.log(srfentityass[rf_index])
                let count = srfentityass[rf_index].tier2_ids.length + srfentityass[rf_index].tier3_ids.length + srfentityass[rf_index].tier1_ids.length + srfentityass[rf_index].tier0_ids.length
                if (count) {
                    index = count
                }
            }

        }
        return (
            <div className="flex justify-content-center">
                {index !== 0 ?
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                        <label onClick={() => { setSrfEntityViewDialog(true); setSelectedSrfEntity({ ...srfentityass[rf_index], title: rowData.title }) }} className="text-underline cur-pointer col-12" >view</label>

                    </div> :
                    'NA'

                }
            </div>
        )
    }
    const viewDfEntityTemplate = (rowData) => {
        let index = 0
        let rf_index = dfentityass.findIndex((i) => { return i.dfId === rowData.data1[0].rf })
        if (rf_index !== -1) {
            let findIndex = dfentityass.filter((i) => { return i.dfId === rowData.data1[0].rf }).length
            if (findIndex) {
                console.log(dfentityass[rf_index])
                let count = dfentityass[rf_index].tier2_ids.length + dfentityass[rf_index].tier3_ids.length + dfentityass[rf_index].tier1_ids.length + dfentityass[rf_index].tier0_ids.length
                if (count) {
                    index = count
                }
            }

        }

        return (
            <div className="flex justify-content-center">
                {index !== 0 ?
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                        <label onClick={() => { setDfEnitityViewDialog(true); setSelectedDfEntity({ ...dfentityass[rf_index], title: rowData.title }) }} className="text-underline cur-pointer col-12" >view</label>

                    </div> :
                    'NA'

                }
            </div>
        )
    }
    const getCoverageText_ = (rowData) => {
        let text = 'Not Found'
        console.log(rowData)
        if (rowData.level === 0) {
            text = 'Corporate'
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.locationId)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.locationId })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }

        } else if (rowData.level === 3) {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.locationId })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }
        return { name: text }
    }
    const getCoverageText = (rowData) => {
        console.log(rowData)
        let text = 'Not Found'


        if (rowData.tier3_id !== undefined && rowData.tier3_id !== null) {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.tier3_id })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        } if (rowData.tier2_id !== undefined && rowData.tier2_id !== null) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.tier2_id })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }

        } else if (rowData.tier1_id !== undefined && rowData.tier1_id !== null) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.tier1_id)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.tier0_id !== undefined && rowData.tier0_id !== null) {
            text = 'Corporate'
        }
        return { name: text }
    }
    const groupEntityTemplate = (option) => {
        console.log(option)
        return (
            <div className="flex align-items-center gap-2">
                {
                    option.items.length ?
                        <div>{option.label + ' (' + option.items.length + ')'}</div>
                        :
                        <div>{option.label}</div>
                }

            </div>
        );
    };
    const itemTemplate = (option) => {
        console.log(option)
        return (
            <div className="flex align-items-center ">

                <div>{option.name}</div>


            </div>
        );
    };
    const getEntityOptions = (item) => {
        let newObj = []
        if (item.tier0_ids.length > 0) {
            newObj.push({ label: 'Corporate', items: [], tier0_id: 0 })
        }
        if (item.tier1_ids.length > 0) {

            let obj = { label: 'Tier1', items: [] }
            item.tier1_ids.filter(i => i != 0).forEach((country) => {
                obj.items.push(getCoverageText({ level: 1, locationId: country, tier1_id: country }))
            })
            newObj.push(obj)

        }
        if (item.tier2_ids.length) {
            let obj = { label: 'Tier2', items: [] }
            item.tier2_ids.filter(i => i != 0).forEach((city) => {
                obj.items.push(getCoverageText({ level: 2, locationId: city, tier2_id: city }))
            })
            newObj.push(obj)
        }
        if (item.tier3_ids.length) {
            let obj = { label: 'Tier3', items: [] }
            item.tier3_ids.filter(i => i != 0).forEach((site) => {
                obj.items.push(getCoverageText({ level: 3, locationId: site, tier3_id: site }))
            })
            newObj.push(obj)
        }
        console.log(newObj, item)
        return newObj



    }
    const coverageTemplate = (rowData) => {
        return <span >{getCoverageText(rowData).name} </span>
    }
    const formTypeTemplate = (rowData) => {
        let type = ''
        console.log(rowData)
        if (rowData.dcfId) {
            type = 'Quantitative'
        } else if (rowData.dfId) {
            type = 'Qualitative'
        } else if (rowData.srfId) {
            type = 'Supplier'
        }
        return <span className='flex justify-content-center' >{type} </span>
    }
    const frequencyTemplate = (rowData) => {
        let text = ""

        let freq_index = frequency_list.findIndex(i => i.id === rowData.frequency)
        if (freq_index !== -1) {
            text = frequency_list[freq_index].name
        }
        return <span className='flex justify-content-center'>{text} </span>
    }
    const startDateTemplate = (rowData) => {
        console.log(rowData)
        return (
            <div className='flex justify-content-center'>
                {
                    (rowData.start_date === undefined || rowData.start_date === null) ?
                        <span></span> :
                        <span>{DateTime.fromISO(rowData.start_date, { zone: 'utc' }).toLocal().toFormat('LLL-yyyy')}</span>
                }
            </div>
        )

    }
    const endDateTemplate = (rowData) => {
        return (
            <div className='flex justify-content-center'>
                {
                    (rowData.end_date === undefined || rowData.end_date === null) ?
                        <span></span> :
                        <span>{DateTime.fromISO(rowData.end_date, { zone: 'utc' }).toLocal().toFormat('LLL-yyyy')}</span>
                }
            </div>
        )
    }
    const reporterListTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                {rowData.reporter_ids === undefined ?
                    <span></span>
                    : <span>    <Badge value={rowData.reporter_ids.length}></Badge></span>
                }
            </div>
        )
    }
    const reviewerListTemplate = (rowData) => {

        return (
            <div className="flex justify-content-center">
                {rowData.reviewer_ids === undefined ?
                    <span></span>
                    : <span> {rowData.reviewer_ids.length ? <Badge value={rowData.reviewer_ids.length}></Badge> : 'Self'}</span>
                }
            </div>
        )
    }
    const approverListTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                {rowData.approver_ids === undefined ?
                    <span></span>
                    : <span>    <Badge value={rowData.approver_ids.length}></Badge></span>
                }
            </div>
        )
    }
    const dcfActionBodyTemplate = (rowData) => {
        console.log(rowData)
        return (
            <>
                {rowData.assId === undefined ?
                    <i className="pi pi-plus" onClick={() => { openDCFEntityUserAss(rowData) }}></i>
                    :
                    <i className="pi pi-pencil" onClick={() => { openDCFEntityUserAss(rowData) }}></i>
                }
            </>
        )
    }
    const allActionBodyTemplate = (rowData) => {
        console.log(rowData)
        return (
            <div className="flex justify-content-center">

                <i className="pi pi-eye" onClick={() => { openAllEntityUserAss(rowData) }}></i>


            </div>
        )
    }
    const srfActionBodyTemplate = (rowData) => {
        console.log(rowData)
        return (
            <>
                {rowData.assId === undefined ?
                    <i className="pi pi-plus" onClick={() => { openSRFEntityUserAss(rowData) }}></i>
                    :
                    <i className="pi pi-pencil" onClick={() => { openSRFEntityUserAss(rowData) }}></i>
                }
            </>
        )
    }
    const dfActionBodyTemplate = (rowData) => {
        console.log(rowData)
        return (
            <>
                {rowData.assId === undefined ?
                    <i className="pi pi-plus" onClick={() => { openDFEntityUserAss(rowData) }}></i>
                    :
                    <i className="pi pi-pencil" onClick={() => { openDFEntityUserAss(rowData) }}></i>
                }
            </>
        )
    }
    const updateDCFEntity = (obj, val) => {

        setSelectedDcfEntity(prev => { return { ...prev, [obj]: val } })
    }
    const updateSRFEntity = (obj, val) => {

        setSelectedSrfEntity(prev => { return { ...prev, [obj]: val } })
    }
    const updateDFEntity = (obj, val) => {

        setSelectedDfEntity(prev => { return { ...prev, [obj]: val } })
    }
    const renderMonthContent = (month, shortMonth, longMonth) => {
        const tooltipText = `Tooltip for month: ${longMonth}`;
        console.log(month)
        return frequencyMonths.includes(month) ? <span title={tooltipText}>{shortMonth} </span> : null
    };
    const updateDFEntityUserAss = (obj, val) => {
        setDfEntityUserObj((prev) => { return { ...prev, [obj]: val } })
        if (obj === 'frequency') {
            if (val !== null) {
                if (val === 1 || val === 6) {
                    setFrequencyMonths([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
                } else if (val === 3 || val === 4 || val === 5) {
                    setFrequencyMonths([0, 3, 6, 9])
                } else {
                    setFrequencyMonths([0, 2, 4, 6, 8, 10])
                }
            }
        }
    }
    const updateDCFEntityUserAss = (obj, val) => {
        setDcfEntityUserObj((prev) => { return { ...prev, [obj]: val } })
        if (obj === 'frequency') {
            if (val !== null) {
                if (val === 1 || val === 6) {
                    setFrequencyMonths([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
                } else if (val === 3 || val === 4 || val === 5) {
                    setFrequencyMonths([0, 3, 6, 9])
                } else {
                    setFrequencyMonths([0, 2, 4, 6, 8, 10])
                }
            }
        }
    }
    const updateSRFEntityUserAss = (obj, val) => {
        setSrfEntityUserObj((prev) => { return { ...prev, [obj]: val } })
        if (obj === 'frequency') {
            if (val !== null) {
                if (val === 1 || val === 6) {
                    setFrequencyMonths([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
                } else if (val === 3 || val === 4 || val === 5) {
                    setFrequencyMonths([0, 3, 6, 9])
                } else {
                    setFrequencyMonths([0, 2, 4, 6, 8, 10])
                }
            }
        }
    }
    const AssignDCFEntity = () => {
        let loc = JSON.parse(JSON.stringify(dcfentityass))
        let ass_dcf = JSON.parse(JSON.stringify(assigneddcflist))
        let dcf_index = loc.findIndex((k) => { return k.dcfId === selecteddcfentity.dcfId })
        let ass_dcf_index = ass_dcf.findIndex((k) => { return k.id === selecteddcfentity.dcfId })

        if (dcf_index === -1 && selecteddcfentity.dcfId !== null) {


            let data = { tier0_ids: selecteddcfentity.tier0_ids, tier1_ids: selecteddcfentity.tier1_ids, tier2_ids: selecteddcfentity.tier2_ids, tier3_ids: selecteddcfentity.tier3_ids, dcfId: selecteddcfentity.dcfId, created_by: selector.id, created_on: DateTime.utc() }
            delete data.title
            if (selecteddcfentity.comments !== undefined) {
                data.comments = selecteddcfentity.comments
            }
            APIServices.post(API.DCF_Entity_UP(selector.id), data).then((res) => {
                let dcfass_ = JSON.parse(JSON.stringify(dcfentityass))
                dcfass_.push({ ...res.data })
                ass_dcf[ass_dcf_index].entity = { ...res.data }
                setAssignedDcfList(ass_dcf)
                setSelectedDcfEntity({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], dcfId: null, title: null })
                setDcfEntityAss(dcfass_)
                setDcfEntityDialog(false)


            })
        } else if (dcf_index !== -1 && loc[dcf_index].id !== undefined) {
            let data = { tier0_ids: selecteddcfentity.tier0_ids, tier1_ids: selecteddcfentity.tier1_ids, tier2_ids: selecteddcfentity.tier2_ids, tier3_ids: selecteddcfentity.tier3_ids, modified_by: selector.id, modified_on: DateTime.utc() }

            if (selecteddcfentity.comments !== undefined) {
                data.comments = selecteddcfentity.comments
            }
            APIServices.patch(API.DCF_Entity_Edit(loc[dcf_index].id), data).then((res) => {

                ass_dcf[ass_dcf_index].entity = { ...selecteddcfentity, data }
                setAssignedDcfList(ass_dcf)
                loc[dcf_index] = { ...selecteddcfentity, data }
                setDcfEntityAss(loc)
                setSelectedDcfEntity({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], dcfId: null, title: null })
                setDcfEntityDialog(false)

            })
        }

    }

    const AssignSRFEntity = () => {
        let loc = JSON.parse(JSON.stringify(srfentityass))
        let ass_dcf = JSON.parse(JSON.stringify(assignedsrflist))
        let dcf_index = loc.findIndex((k) => { return k.srfId === selectedsrfentity.srfId })
        let ass_dcf_index = ass_dcf.findIndex((k) => { return k.id === selectedsrfentity.srfId })

        if (dcf_index === -1 && selectedsrfentity.srfId !== null) {


            let data = { tier0_ids: selectedsrfentity.tier0_ids, tier1_ids: selectedsrfentity.tier1_ids, tier2_ids: selectedsrfentity.tier2_ids, tier3_ids: selectedsrfentity.tier3_ids, srfId: selectedsrfentity.srfId, created_by: selector.id, created_on: DateTime.utc() }
            delete data.title
            if (selectedsrfentity.comments !== undefined) {
                data.comments = selectedsrfentity.comments
            }
            APIServices.post(API.SRF_Entity_UP(selector.id), data).then((res) => {
                let dcfass_ = JSON.parse(JSON.stringify(srfentityass))
                dcfass_.push({ ...res.data })
                ass_dcf[ass_dcf_index].entity = { ...res.data }
                setAssignedSrfList(ass_dcf)
                setSelectedSrfEntity({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], srfId: null, title: null })
                setSrfEntityAss(dcfass_)
                setSrfEntityDialog(false)


            })
        } else if (dcf_index !== -1 && loc[dcf_index].id !== undefined) {
            let data = { tier0_ids: selectedsrfentity.tier0_ids, tier1_ids: selectedsrfentity.tier1_ids, tier2_ids: selectedsrfentity.tier2_ids, tier3_ids: selectedsrfentity.tier3_ids, modified_by: selector.id, modified_on: DateTime.utc() }
            if (selectedsrfentity.comments !== undefined) {
                data.comments = selectedsrfentity.comments
            }

            APIServices.patch(API.SRF_Entity_Edit(loc[dcf_index].id), data).then((res) => {

                ass_dcf[ass_dcf_index].entity = { ...selectedsrfentity, data }
                setAssignedSrfList(ass_dcf)
                loc[dcf_index] = { ...selectedsrfentity, data }
                setSrfEntityAss(loc)
                setSelectedSrfEntity({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], srfId: null, title: null })
                setSrfEntityDialog(false)

            })
        }

    }
    const AssignDFEntity = () => {
        let loc = JSON.parse(JSON.stringify(dfentityass))
        let ass_dcf = [], ass_dcf_bk = []
        if (selecteddfentity.type === 1) {
            ass_dcf = JSON.parse(JSON.stringify(requiredList))
            ass_dcf_bk = JSON.parse(JSON.stringify(requiredListBK))
        } else if (selecteddfentity.type === 2) {
            ass_dcf = JSON.parse(JSON.stringify(requiredList2))
            ass_dcf_bk = JSON.parse(JSON.stringify(requiredList2BK))
        }
        JSON.parse(JSON.stringify(requiredList))
        let dcf_index = loc.findIndex((k) => { return k.dfId === selecteddfentity.dfId })
        let ass_dcf_index = ass_dcf.findIndex((k) => { return k.data1[0].rf === selecteddfentity.dfId })
        let ass_dcf_index_bk = ass_dcf_bk.findIndex((k) => { return k.data1[0].rf === selecteddfentity.dfId })
        console.log(selecteddfentity, ass_dcf)
        if (dcf_index === -1 && selecteddfentity.dfId !== null && selecteddfentity.type !== null) {


            let data = { tier0_ids: selecteddfentity.tier0_ids, type: selecteddfentity.type, tier1_ids: selecteddfentity.tier1_ids, tier2_ids: selecteddfentity.tier2_ids, tier3_ids: selecteddfentity.tier3_ids, dfId: selecteddfentity.dfId, created_by: selector.id, created_on: DateTime.utc() }
            delete data.title
            if (selecteddfentity.comments !== undefined) {
                data.comments = selecteddfentity.comments
            }
            APIServices.post(API.DF_Entity_UP(selector.id), data).then((res) => {
                let dcfass_ = JSON.parse(JSON.stringify(dfentityass))
                dcfass_.push({ ...res.data })
                ass_dcf[ass_dcf_index].entity = { ...res.data }
                ass_dcf_bk[ass_dcf_index_bk].entity = { ...res.data }
                if (data.type === 1) {
                    setRequiredListBK(ass_dcf_bk)
                    setRequiredList(ass_dcf)
                } else if (data.type === 2) {
                    setRequiredList2BK(ass_dcf_bk)
                    setRequiredList2(ass_dcf)
                }

                setSelectedDfEntity({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], dfId: null, title: null })
                setDfEntityAss(dcfass_)
                setDfEntityDialog(false)


            })
        } else if (dcf_index !== -1 && loc[dcf_index].id !== undefined && selecteddfentity.type !== null) {
            let data = { tier0_ids: selecteddfentity.tier0_ids, type: selecteddfentity.type, tier1_ids: selecteddfentity.tier1_ids, tier2_ids: selecteddfentity.tier2_ids, tier3_ids: selecteddfentity.tier3_ids, modified_by: selector.id, modified_on: DateTime.utc() }

            if (selecteddfentity.comments !== undefined) {
                data.comments = selecteddfentity.comments
            }
            APIServices.patch(API.DF_Entity_Edit(loc[dcf_index].id), data).then((res) => {
                ass_dcf_bk[ass_dcf_index_bk].entity = { ...selecteddfentity, data }
                ass_dcf[ass_dcf_index].entity = { ...selecteddfentity, data }
                if (data.type === 1) {
                    setRequiredListBK(ass_dcf_bk)
                    setRequiredList(ass_dcf)
                } else if (data.type === 2) {
                    setRequiredList2BK(ass_dcf_bk)
                    setRequiredList2(ass_dcf)
                }

                loc[dcf_index] = { ...selecteddfentity, data }
                setDfEntityAss(loc)
                setSelectedDfEntity({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], dfId: null, title: null })
                setDfEntityDialog(false)

            })
        }

    }
    const AssignDFUserEntity = () => {
        setSubmitted(true)
        console.log(dfentityuserobj)
        let locdcfuserass = JSON.parse(JSON.stringify(dfentityuserass))
        let loc_index = locdcfuserass.findIndex(i => dfentityuserobj.id === i.id)
        if (dfentityuserobj.id === undefined || dfentityuserobj.id === null) {
            if (dfentityuserobj.level !== undefined && dfentityuserobj.locationId !== undefined && getCoverageId(dfentityuserobj).result && dfentityuserobj.level !== null && dfentityuserobj.locationId !== null && dfentityuserobj.frequency !== null && dfentityuserobj.dfId !== undefined && dfentityuserobj.dfId !== null && dfentityuserobj.reporter_ids.length && dfentityuserobj.approver_ids.length && dfentityuserobj.start_date !== null) {
                let newObj = { ...getCoverageId(dfentityuserobj).data, type: dfentityuserobj.type, dfId: dfentityuserobj.dfId, level: dfentityuserobj.level, locationId: dfentityuserobj.locationId, created_on: DateTime.utc(), created_by: selector.id, frequency: dfentityuserobj.frequency, start_date: dfentityuserobj.start_date, reporter_ids: dfentityuserobj.reporter_ids, reviewer_ids: dfentityuserobj.reviewer_ids, approver_ids: dfentityuserobj.approver_ids }


                APIServices.post(API.DF_Entity_User_UP(selector.id), newObj).then((res) => {
                    let locres = { ...res.data, end_date: null }
                    locdcfuserass.push(locres)
                    setDfEntityUserAss(locdcfuserass)
                    setDfEntityUserDialog(false)
                    setSubmitted(false)

                })
            }

        } else {
            if (dfentityuserobj.frequency !== null && dfentityuserobj.dfId !== undefined && dfentityuserobj.dfId !== null && dfentityuserobj.reporter_ids.length && dfentityuserobj.approver_ids.length && dfentityuserobj.start_date !== null) {
                let newObj = { modified_on: DateTime.utc(), modified_by: selector.id, reporter_ids: dfentityuserobj.reporter_ids, reviewer_ids: dfentityuserobj.reviewer_ids, approver_ids: dfentityuserobj.approver_ids }
                if (dfentityuserobj.end_date !== null) {
                    newObj['end_date'] = dfentityuserobj.end_date
                }

                APIServices.patch(API.DF_Entity_User_Edit(dfentityuserobj.id), newObj).then((res) => {

                    locdcfuserass[loc_index] = { ...locdcfuserass[loc_index], id: dfentityuserobj.id, ...newObj }
                    console.log(locdcfuserass)
                    setDfEntityUserAss(locdcfuserass)
                    setDfEntityUserDialog(false)
                    setSubmitted(false)

                })
            }
        }
    }
    const getCoverageId = (rowData) => {

        if (rowData.tier3_id !== undefined && rowData.tier3_id !== null) {
            return { result: true, data: { tier3_id: rowData.tier3_id } }
        } else if (rowData.tier2_id !== undefined && rowData.tier2_id !== null) {
            return { result: true, data: { tier2_id: rowData.tier2_id } }
        } else if (rowData.tier1_id !== undefined && rowData.tier1_id !== null) {
            return { result: true, data: { tier1_id: rowData.tier1_id } }
        } if (rowData.tier0_id !== undefined && rowData.tier0_id !== null) {
            return { result: true, data: { tier0_id: rowData.tier0_id } }
        } else {
            return { result: false }
        }
    }
    const AssignDCFUserEntity = () => {
        setSubmitted(true)
        console.log(dcfentityuserobj)
        let locdcfuserass = JSON.parse(JSON.stringify(dcfentityuserass))
        let loc_index = locdcfuserass.findIndex(i => dcfentityuserobj.id === i.id)
        let ass = JSON.parse(JSON.stringify(dcfentityass)).findIndex(i => dcfentityuserobj.dcfId === i.dcfId)
        console.log(ass, dcfentityass)
        if (ass !== -1) {
            if (dcfentityuserobj.id === undefined || dcfentityuserobj.id === null) {
                if (dcfentityuserobj.level !== undefined && getCoverageId(dcfentityuserobj).result && dcfentityuserobj.locationId !== undefined && dcfentityuserobj.level !== null && dcfentityuserobj.locationId !== null && dcfentityuserobj.frequency !== null && dcfentityuserobj.dcfId !== undefined && dcfentityuserobj.dcfId !== null && (stdlist.length ? dcfentityuserobj.standard !== null : true) && dcfentityuserobj.reporter_ids.length && dcfentityuserobj.approver_ids.length && dcfentityuserobj.start_date !== null) {
                    let newObj = { ...getCoverageId(dcfentityuserobj).data, entityAssId: dcfentityass[ass].id, dcfId: dcfentityuserobj.dcfId, level: dcfentityuserobj.level, locationId: dcfentityuserobj.locationId, created_on: DateTime.utc(), created_by: selector.id, frequency: dcfentityuserobj.frequency, start_date: dcfentityuserobj.start_date, reporter_ids: dcfentityuserobj.reporter_ids, reviewer_ids: dcfentityuserobj.reviewer_ids, approver_ids: dcfentityuserobj.approver_ids }
                    if (dcfentityuserobj.standard !== null) {
                        newObj['standard'] = dcfentityuserobj.standard
                    }

                    APIServices.post(API.DCF_Entity_User_UP(selector.id), newObj).then((res) => {
                        let locres = { ...res.data, end_date: null }
                        locdcfuserass.push(locres)
                        setDcfEntityUserAss(locdcfuserass)
                        setDcfEntityUserDialog(false)
                        setSubmitted(false)

                    })
                }

            } else {
                if (dcfentityuserobj.frequency !== null && dcfentityuserobj.dcfId !== undefined && dcfentityuserobj.dcfId !== null && dcfentityuserobj.reporter_ids.length && dcfentityuserobj.approver_ids.length && dcfentityuserobj.start_date !== null) {
                    let newObj = { modified_on: DateTime.utc(), modified_by: selector.id, reporter_ids: dcfentityuserobj.reporter_ids, reviewer_ids: dcfentityuserobj.reviewer_ids, approver_ids: dcfentityuserobj.approver_ids }
                    if (dcfentityuserobj.end_date !== null) {
                        newObj['end_date'] = dcfentityuserobj.end_date
                    }

                    APIServices.patch(API.DCF_Entity_User_Edit(dcfentityuserobj.id), newObj).then((res) => {

                        locdcfuserass[loc_index] = { ...locdcfuserass[loc_index], id: dcfentityuserobj.id, ...newObj }
                        console.log(locdcfuserass)
                        setDcfEntityUserAss(locdcfuserass)
                        setDcfEntityUserDialog(false)
                        setSubmitted(false)

                    })
                }
            }
        }
    }
    const AssignSRFUserEntity = () => {
        setSubmitted(true)
        console.log(srfentityuserobj)
        let locdcfuserass = JSON.parse(JSON.stringify(srfentityuserass))
        let loc_index = locdcfuserass.findIndex(i => srfentityuserobj.id === i.id)

        if (srfentityuserobj.id === undefined || srfentityuserobj.id === null) {
            if (srfentityuserobj.level !== undefined && getCoverageId(srfentityuserobj).result && srfentityuserobj.locationId !== undefined && srfentityuserobj.level !== null && srfentityuserobj.locationId !== null && srfentityuserobj.frequency !== null && srfentityuserobj.srfId !== undefined && srfentityuserobj.srfId !== null && srfentityuserobj.reporter_ids.length && srfentityuserobj.approver_ids.length && srfentityuserobj.start_date !== null) {
                let newObj = { ...getCoverageId(srfentityuserobj).data, srfId: srfentityuserobj.srfId, level: srfentityuserobj.level, locationId: srfentityuserobj.locationId, created_on: DateTime.utc(), created_by: selector.id, frequency: srfentityuserobj.frequency, start_date: srfentityuserobj.start_date, reporter_ids: srfentityuserobj.reporter_ids, reviewer_ids: srfentityuserobj.reviewer_ids, approver_ids: srfentityuserobj.approver_ids }


                APIServices.post(API.SRF_Entity_User_UP(selector.id), newObj).then((res) => {
                    let locres = { ...res.data, end_date: null }
                    locdcfuserass.push(locres)
                    setSrfEntityUserAss(locdcfuserass)
                    setSrfEntityUserDialog(false)
                    setSubmitted(false)

                })
            }

        } else {
            if (srfentityuserobj.frequency !== null && srfentityuserobj.srfId !== undefined && srfentityuserobj.srfId !== null && srfentityuserobj.reporter_ids.length && srfentityuserobj.approver_ids.length && srfentityuserobj.start_date !== null) {
                let newObj = { modified_on: DateTime.utc(), modified_by: selector.id, reporter_ids: srfentityuserobj.reporter_ids, reviewer_ids: srfentityuserobj.reviewer_ids, approver_ids: srfentityuserobj.approver_ids }
                if (srfentityuserobj.end_date !== null) {
                    newObj['end_date'] = srfentityuserobj.end_date
                }

                APIServices.patch(API.SRF_Entity_User_Edit(srfentityuserobj.id), newObj).then((res) => {

                    locdcfuserass[loc_index] = { ...locdcfuserass[loc_index], id: srfentityuserobj.id, ...newObj }
                    console.log(locdcfuserass)
                    setSrfEntityUserAss(locdcfuserass)
                    setSrfEntityUserDialog(false)
                    setSubmitted(false)

                })
            }
        }
    }
    const dcfEntityToUserAssignment = (arr) => {
        const result = [];
        let locrfuser = JSON.parse(JSON.stringify(dcfentityuserass))
        arr.forEach(item => {
            const { dcfId, tier0_ids, tier1_ids, tier2_ids, tier3_ids } = item;
            if (tier0_ids.length > 0) {
                tier0_ids.forEach(other => {
                    let rfuserindex = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === other && i.level === 0 && i.tier0_id === other)
                    if (rfuserindex === -1) {
                        result.push({ dcfId, level: 0, locationId: other, tier0_id: other });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === other && i.level === 0 && i.tier0_id === other && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {
                            console.log(locrfuser[rfuserindex2])
                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dcfId, level: 0, locationId: other, tier0_id: other });
                        }

                        result.push(...locrfuser.filter(i => i.dcfId === dcfId && i.locationId === other && i.level === 0 && i.tier0_id === other && i.start_date !== null && i.end_date !== null))

                    }

                });
            }
            if (tier1_ids.length > 0) {
                tier1_ids.forEach(country => {
                    const level = 1;
                    let rfuserindex = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === country && i.tier1_id === country && i.level === level)

                    if (rfuserindex === -1) {
                        result.push({ dcfId, level, locationId: country, tier1_id: country });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === country && i.tier1_id === country && i.level === level && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {

                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dcfId, level, locationId: country, tier1_id: country });
                        }

                        result.push(...locrfuser.filter(i => i.dcfId === dcfId && i.locationId === country && i.tier1_id === country && i.level === level && i.start_date !== null && i.end_date !== null))

                    }

                });
            }
            if (tier2_ids.length > 0) {
                tier2_ids.forEach(city => {
                    let rfuserindex = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === city && i.level === 2 && i.tier2_id === city)
                    if (rfuserindex === -1) {
                        result.push({ dcfId, level: 2, locationId: city, tier2_id: city });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === city && i.level === 2 && i.tier2_id === city && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {

                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dcfId, level: 2, locationId: city, tier2_id: city });
                        }

                        result.push(...locrfuser.filter(i => i.dcfId === dcfId && i.locationId === city && i.level === 2 && i.tier2_id === city && i.start_date !== null && i.end_date !== null))

                    }

                });
            }
            if (tier3_ids.length > 0) {
                tier3_ids.forEach(site => {
                    let rfuserindex = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === site && i.level === 3 && i.tier3_id === site)
                    if (rfuserindex === -1) {
                        result.push({ dcfId, level: 3, locationId: site, tier3_id: site });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === site && i.tier3_id === site && i.level === 3 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {

                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dcfId, level: 3, locationId: site, tier3_id: site });
                        }

                        result.push(...locrfuser.filter(i => i.dcfId === dcfId && i.locationId === site && i.tier3_id === site && i.level === 3 && i.start_date !== null && i.end_date !== null))


                    }

                });
            }
        });
        console.log(result)
        return result;
    }
    const srfEntityToUserAssignment = (arr) => {
        const result = [];
        let locrfuser = JSON.parse(JSON.stringify(srfentityuserass))
        console.log(srfentityuserass)
        arr.forEach(item => {
            const { srfId, tier0_ids, tier1_ids, tier2_ids, tier3_ids } = item;
            if (tier0_ids.length > 0) {
                tier0_ids.forEach(other => {
                    let rfuserindex = locrfuser.findIndex(i => i.srfId === srfId && i.locationId === other && i.level === 0 && i.tier0_id === other)
                    if (rfuserindex === -1) {
                        result.push({ srfId, level: 0, locationId: other, tier0_id: other });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.srfId === srfId && i.locationId === other && i.level === 0 && i.tier0_id === other && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {
                            console.log(locrfuser[rfuserindex2])
                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ srfId, level: 0, locationId: other, tier0_id: other });
                        }

                        result.push(...locrfuser.filter(i => i.srfId === srfId && i.locationId === other && i.level === 0 && i.tier0_id === other && i.start_date !== null && i.end_date !== null))

                    }

                });
            }
            if (tier1_ids.length > 0) {
                tier1_ids.forEach(country => {
                    const level = 1;
                    let rfuserindex = locrfuser.findIndex(i => i.srfId === srfId && i.tier1_id === country && i.locationId === country && i.level === level)

                    if (rfuserindex === -1) {
                        result.push({ srfId, level, locationId: country, tier1_id: country });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.srfId === srfId && i.tier1_id === country && i.locationId === country && i.level === level && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {

                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ srfId, level, locationId: country, tier1_id: country });
                        }

                        result.push(...locrfuser.filter(i => i.srfId === srfId && i.tier1_id === country && i.locationId === country && i.level === level && i.start_date !== null && i.end_date !== null))

                    }

                });
            }
            if (tier2_ids.length > 0) {
                tier2_ids.forEach(city => {
                    let rfuserindex = locrfuser.findIndex(i => i.srfId === srfId && i.tier2_id === city && i.locationId === city && i.level === 2)
                    if (rfuserindex === -1) {
                        result.push({ srfId, level: 2, locationId: city, tier2_id: city });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.srfId === srfId && i.tier2_id === city && i.locationId === city && i.level === 2 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {

                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ srfId, level: 2, locationId: city, tier2_id: city });
                        }

                        result.push(...locrfuser.filter(i => i.srfId === srfId && i.tier2_id === city && i.locationId === city && i.level === 2 && i.start_date !== null && i.end_date !== null))

                    }

                });
            }
            if (tier3_ids.length > 0) {
                tier3_ids.forEach(site => {
                    let rfuserindex = locrfuser.findIndex(i => i.srfId === srfId && i.tier3_id === site && i.locationId === site && i.level === 3)
                    if (rfuserindex === -1) {
                        result.push({ srfId, level: 3, locationId: site, tier3_id: site });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.srfId === srfId && i.tier3_id === site && i.locationId === site && i.level === 3 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {

                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ srfId, level: 3, locationId: site, tier3_id: site });
                        }

                        result.push(...locrfuser.filter(i => i.srfId === srfId && i.tier3_id === site && i.locationId === site && i.level === 3 && i.start_date !== null && i.end_date !== null))


                    }

                });
            }
        });
        console.log(result)
        return result;
    }
    const dfEntityToUserAssignment = (arr) => {
        const result = [];
        let locrfuser = JSON.parse(JSON.stringify(dfentityuserass))
        arr.forEach(item => {
            const { dfId, tier0_ids, tier1_ids, tier2_ids, tier3_ids } = item;
            const type = requiredList.findIndex(i => i.data1[0].rf === dfId) === -1 ? 2 : 1
            if (tier0_ids.length > 0) {
                tier0_ids.forEach(other => {
                    let rfuserindex = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === other && i.tier0_id === other && i.level === 0)
                    if (rfuserindex === -1) {
                        result.push({ dfId, level: 0, locationId: other, type, tier0_id: other });
                    } else {


                        let rfuserindex2 = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === other && i.tier0_id === other && i.level === 0 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {
                            console.log(locrfuser[rfuserindex2])
                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dfId, level: 0, locationId: other, type, tier0_id: other });
                        }

                        result.push(...locrfuser.filter(i => i.dfId === dfId && i.locationId === other && i.tier0_id === other && i.level === 0 && i.start_date !== null && i.end_date !== null))



                    }

                });
            }
            if (tier1_ids.length > 0) {
                tier1_ids.forEach(country => {
                    const level = 1;
                    let rfuserindex = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === country && i.tier1_id === country && i.level === level)

                    if (rfuserindex === -1) {
                        result.push({ dfId, level, locationId: country, type, tier1_id: country });
                    } else {

                        let rfuserindex2 = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === country && i.tier1_id === country && i.level === 1 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {
                            console.log(locrfuser[rfuserindex2])
                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dfId, level: 1, locationId: country, type, tier1_id: country });
                        }

                        result.push(...locrfuser.filter(i => i.dfId === dfId && i.locationId === country && i.tier1_id === country && i.level === 1 && i.start_date !== null && i.end_date !== null))



                    }

                });
            }
            if (tier2_ids.length > 0) {
                tier2_ids.forEach(city => {
                    let rfuserindex = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === city && i.tier2_id === city && i.level === 2)
                    if (rfuserindex === -1) {
                        result.push({ dfId, level: 2, locationId: city, type, tier2_id: city });
                    } else {


                        let rfuserindex2 = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === city && i.tier2_id === city && i.level === 2 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {
                            console.log(locrfuser[rfuserindex2])
                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dfId, level: 2, locationId: city, type, tier2_id: city });
                        }

                        result.push(...locrfuser.filter(i => i.dfId === dfId && i.locationId === city && i.tier2_id === city && i.level === 2 && i.start_date !== null && i.end_date !== null))




                    }

                });
            }
            if (tier3_ids.length > 0) {
                tier3_ids.forEach(site => {
                    let rfuserindex = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === site && i.tier3_id === site && i.level === 3)
                    if (rfuserindex === -1) {
                        result.push({ dfId, level: 3, locationId: site, type, tier3_id: site });
                    } else {



                        let rfuserindex2 = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === site && i.tier3_id === site && i.level === 3 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {
                            console.log(locrfuser[rfuserindex2])
                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dfId, level: 3, locationId: site, type, tier3_id: site });
                        }

                        result.push(...locrfuser.filter(i => i.dfId === dfId && i.locationId === site && i.tier3_id === site && i.level === 3 && i.start_date !== null && i.end_date !== null))


                    }

                });
            }
        });
        console.log(result)
        return result;
    }
    const getDcf = (rf) => {
        console.log(rf)
        let loc = assigneddcflist.findIndex((i) => { return i.id === rf.dcfId })
        if (loc !== -1) {
            return { ...assigneddcflist[loc], ...rf, assId: rf.id }
        } else {
            return null
        }
    }
    const getRf = (rf) => {
        console.log(rf)
        let loc1 = requiredList.findIndex((i) => { return i.data1[0].rf === rf.dfId })
        let loc2 = requiredList2.findIndex((i) => { return i.data1[0].rf === rf.dfId })
        let loc1_bk = requiredListBK.findIndex((i) => { return i.data1[0].rf === rf.dfId })
        let loc2_bk = requiredList2BK.findIndex((i) => { return i.data1[0].rf === rf.dfId })
        if (loc1 !== -1 || loc2 !== -1) {
            if (loc1 !== -1 && loc2 !== -1) {
                return null
            } else if (loc1 !== -1) {
                requiredListBK[loc1_bk] = { ...requiredListBK[loc1_bk], ...rf, assId: rf.id }
                return { ...requiredList[loc1], ...rf, assId: rf.id }
            } else if (loc2 !== -1) {
                requiredList2BK[loc2_bk] = { ...requiredList2BK[loc2_bk], ...rf, assId: rf.id }
                return { ...requiredList2[loc2], ...rf, assId: rf.id }
            }


        } else {
            return null
        }
    }
    const getSrf = (rf) => {
        console.log(rf)
        let loc = assignedsrflist.findIndex((i) => { return i.id === rf.srfId })
        if (loc !== -1) {
            return { ...assignedsrflist[loc], ...rf, assId: rf.id }
        } else {
            return null
        }
    }
    const renderTable = (val) => {
        console.log(val)
        setSelectedFramework(val)
        let filtered = []
        if (val.length) {
            filtered = requiredListBK.filter(obj => {
                return obj.overallTags.some(tagsArray => {
                    return tagsArray.some(tag => {
                        return val.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
                    });
                });
            });
        } else {
            filtered = requiredListBK
        }
        console.log(filtered.length)
        // let filtered = requiredListBK.filter((i) => { return (i.overallTags.some(array => array.some(item => item.includes(val))) || val === 'All') })
        setRequiredList(filtered)
    }
    const renderTable2 = (val) => {
        setSelectedFramework2(val)
        let filtered = []
        if (val.length) {
            filtered = requiredList2BK.filter(obj => {
                return obj.overallTags.some(tagsArray => {
                    return tagsArray.some(tag => {
                        return val.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
                    });
                });
            });
        } else {
            filtered = requiredList2BK
        }
        // let filtered = requiredList2BK.filter((i) => { return (i.overallTags.some(array => array.some(item => item.includes(val))) || val === 'All') })
        setRequiredList2(filtered)
    }
    return (<>
        <div style={{
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
            fontWeight: '600', marginBottom: 10
        }} >Data Assignment</div>
        <TabView activeIndex={activeIndex} onTabChange={(e) => { setActiveIndex(e.index); }} panelContainerStyle={{ height: 'calc(100vh - 200px)' }}>
            <TabPanel header={"Quantitative Assignment" + (!load ? `(${assigneddcflist.length})` : '')} >
                <DataTable loading={load} value={assigneddcflist} key={'id'} scrollable paginator rows={10} emptyMessage='DCF Not Found'>
                    <Column header='Description' field='title' />
                    <Column alignHeader={'center'} header='Add Entity' body={addQNEntityTemplate} />
                    <Column alignHeader={'center'} header='Assign Entity User(s)' body={viewDcfEntityTemplate} />
                </DataTable>
            </TabPanel>
            <TabPanel header={"Qualitative (Required)" + (!load ? `(${requiredList.length})` : '')} >
                <div>
                    <div style={{ marginBottom: 10 }}>
                        <label
                            style={{
                                marginRight: 10,
                            }}
                        >
                            Filter by Standards / Frameworks / Disclosures
                        </label>
                        <MultiSelect display="chip" style={{ width: 300 }} value={selectedFramework} onChange={(e) => renderTable(e.value)} options={assFramework} optionLabel="title" optionValue="title"
                            filter={true} placeholder="Select" panelClassName={'hidefilter'} />

                    </div>
                </div>
                <DataTable loading={load} value={requiredList} scrollable >
                    <Column field='cat_title' header='Category' />
                    <Column field='top_title' header='Topic' />
                    <Column field='title' body={titleTemplate} header='Aspect' />
                    <Column header='Requirement' body={rrTemplate} />
                    <Column alignHeader={'center'} header='Assigned Entities' body={addQLEntityTemplate} />
                    <Column alignHeader={'center'} header='Assign Entity User(s)' body={viewDfEntityTemplate} />
                </DataTable>
            </TabPanel>
            <TabPanel header={"Qualitative (Optional)" + (!load ? `(${requiredList2.length})` : '')} >
                <div>
                    <div style={{ marginBottom: 10 }}>
                        <label
                            style={{
                                marginRight: 10,
                            }}
                        >
                            Filter by Standards / Frameworks / Disclosures
                        </label>
                        <MultiSelect display="chip" style={{ width: 300 }} value={selectedFramework2} onChange={(e) => renderTable2(e.value)} options={assFramework2} optionLabel="title" optionValue="title"
                            filter={true} placeholder="Select" panelClassName={'hidefilter'} />

                    </div>
                </div>
                <DataTable loading={load} value={requiredList2} scrollable >
                    <Column field='cat_title' header='Category' />
                    <Column field='top_title' header='Topic' />
                    <Column field='title' body={titleTemplate} header='Aspect' />
                    <Column header='Requirement' body={rrTemplate} />
                    <Column alignHeader={'center'} header='Assigned Entities' body={addQLEntityTemplate} />
                    <Column alignHeader={'center'} header='Assign Entity User(s)' body={viewDfEntityTemplate} />

                </DataTable>
            </TabPanel>
            <TabPanel header={"Supplier Form Assignment" + (!load ? `(${assignedsrflist.length})` : '')} >
                <DataTable loading={load} value={assignedsrflist} key={'id'} scrollable paginator rows={10} emptyMessage='DCF Not Found'>
                    <Column header='Description' field='title' />
                    <Column alignHeader={'center'} header='Add Entity' body={addSRFEntityTemplate} />
                    <Column alignHeader={'center'} header='Assign Entity User(s)' body={viewSrfEntityTemplate} />
                </DataTable>

            </TabPanel>
            <TabPanel header={"Summary" + (!load ? `(${[...dcfentityuserass, ...dfentityuserass, ...srfentityuserass].length})` : '')}  >
                <DataTable loading={load} value={[...dcfentityuserass, ...dfentityuserass, ...srfentityuserass]} key={'id'} scrollable paginator rows={10} emptyMessage='DCF Not Found'>
                    <Column header="Type" body={formTypeTemplate} />
                    <Column header="Entity" field='locationId' body={coverageTemplate} />
                    <Column alignHeader={'center'} header="Frequency" field='frequency' body={frequencyTemplate} />
                    <Column alignHeader={'center'} header="Start Date" field='start_date' body={startDateTemplate} />
                    <Column alignHeader={'center'} header="End Date" field='end_date' body={endDateTemplate} />
                    <Column alignHeader={'center'} header="Reporter" field='reporter_ids' body={reporterListTemplate} />
                    <Column alignHeader={'center'} header="Reviewer" field='reviewer_ids' body={reviewerListTemplate} />
                    <Column alignHeader={'center'} header="Approver" field='approver_ids' body={approverListTemplate} />
                    <Column alignHeader={'center'} header="View" body={allActionBodyTemplate} />
                </DataTable>

            </TabPanel>
        </TabView>
        <Dialog
            visible={dcfentitydialog}
            style={{
                width: "75%",
            }}
            header={`Assign Entity for " ` + selecteddcfentity.title + ` "`}
            modal
            className="p-fluid"

            onHide={() => { setDcfEntityDialog(false) }}
        >
            <div>
                <div className="col-12 grid">
                    <div className="col-6 ">
                        <div className='col-12 grid'>
                            <div className="flex align-items-center">
                                <Checkbox inputId="corporate" name="corporate" onChange={(e) => { updateDCFEntity('tier0_ids', e.checked ? [0] : []) }} checked={selecteddcfentity.tier0_ids.includes(0)} />
                                <label htmlFor="corporate" className="ml-2">Corporate</label>
                            </div>
                        </div>
                        <div className='col-12 grid align-items-center justify-content-between'>
                            <div className="col-3">
                                <label className="fs-16 fw-5">{label1}</label>
                            </div>
                            <div className="col-8">

                                <MultiSelect filter={true} className="w-full " panelClassName={'hidefilter'} optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selecteddcfentity.tier1_ids} options={selectedloclist.tier1} onChange={(e) => { updateDCFEntity('tier1_ids', e.value) }} />
                            </div>
                        </div>
                        <div className='col-12 grid align-items-center justify-content-between'>
                            <div className="col-3">
                                <label className="fs-16 fw-5">{label2}</label>
                            </div>
                            <div className="col-8">
                                <MultiSelect filter={true} className="w-full " panelClassName={'hidefilter'} optionLabel="name" multiple optionValue="id" style={{ width: '100%' }} value={selecteddcfentity.tier2_ids} options={selectedloclist.tier2} onChange={(e) => { updateDCFEntity('tier2_ids', e.value) }} />
                            </div>
                        </div>
                        <div className='col-12 grid align-items-center justify-content-between'>
                            <div className="col-3">
                                <label className="fs-16 fw-5">{label3}</label>
                            </div>
                            <div className="col-8">
                                <MultiSelect filter={true} className="w-full " panelClassName={'hidefilter'} optionLabel="name" multiple optionValue="id" style={{ width: '100%' }} value={selecteddcfentity.tier3_ids} options={selectedloclist.tier3} onChange={(e) => { updateDCFEntity('tier3_ids', e.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <ListBox options={getEntityOptions(selecteddcfentity)} optionLabel="name"
                            optionGroupLabel="name" optionGroupChildren="items" listClassName="dis-option-hover" itemTemplate={itemTemplate} optionGroupTemplate={groupEntityTemplate} className="w-full" listStyle={{ height: '250px' }} />

                    </div>
                </div>
                <div className="col-12" style={{ padding: 15 }}>
                    <label className="col-12">Justification for selection of the above entities and for  non-assignment (if applicable) </label>

                    <div className="  flex justify-content-center">
                        <InputTextarea style={{ height: 150, overflow: 'auto', resize: 'none' }} value={selecteddcfentity.comments} autoResize={false} onChange={(e) => { updateDCFEntity('comments', e.target.value) }} />
                    </div>


                </div>
                <div className="flex justify-content-end">
                    <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { AssignDCFEntity() }}>Assign Entity</Button>
                </div>

            </div>
        </Dialog>
        <Dialog
            visible={dcfentityviewdialog}
            style={{
                width: "75%",
            }}
            header={`Assign Entity User for " ` + selecteddcfentity.title + ` "`}
            modal
            className="p-fluid"

            onHide={() => { setDcfEntityViewDialog(false) }}
        >
            <div>
                <DataTable value={dcfEntityToUserAssignment([selecteddcfentity]).map((i) => { return getDcf(i) }).filter(i => i !== null)} scrollable rows={10}  >

                    <Column header="Entity" field='locationId' body={coverageTemplate} />
                    <Column alignHeader={'center'} header="Frequency" field='frequency' body={frequencyTemplate} />
                    <Column alignHeader={'center'} header="Start Date" field='start_date' body={startDateTemplate} />
                    <Column alignHeader={'center'} header="End Date" field='end_date' body={endDateTemplate} />
                    <Column alignHeader={'center'} header="Reporter" field='reporter_ids' body={reporterListTemplate} />
                    <Column alignHeader={'center'} header="Reviewer" field='reviewer_ids' body={reviewerListTemplate} />
                    <Column alignHeader={'center'} header="Approver" field='approver_ids' body={approverListTemplate} />
                    <Column alignHeader={'center'} header="Action" body={dcfActionBodyTemplate} />
                </DataTable>

            </div>
        </Dialog>
        <Dialog
            visible={srfentityviewdialog}
            style={{
                width: "75%",
            }}
            header={`Assign Entity User for " ` + selectedsrfentity.title + ` "`}
            modal
            className="p-fluid"

            onHide={() => { setSrfEntityViewDialog(false) }}
        >
            <div>
                <DataTable value={srfEntityToUserAssignment([selectedsrfentity]).map((i) => { return getSrf(i) }).filter(i => i !== null)} scrollable rows={10}  >

                    <Column header="Entity" field='locationId' body={coverageTemplate} />
                    <Column alignHeader={'center'} header="Frequency" field='frequency' body={frequencyTemplate} />
                    <Column alignHeader={'center'} header="Start Date" field='start_date' body={startDateTemplate} />
                    <Column alignHeader={'center'} header="End Date" field='end_date' body={endDateTemplate} />
                    <Column alignHeader={'center'} header="Reporter" field='reporter_ids' body={reporterListTemplate} />
                    <Column alignHeader={'center'} header="Reviewer" field='reviewer_ids' body={reviewerListTemplate} />
                    <Column alignHeader={'center'} header="Approver" field='approver_ids' body={approverListTemplate} />
                    <Column alignHeader={'center'} header="Action" body={srfActionBodyTemplate} />
                </DataTable>

            </div>
        </Dialog>
        <Dialog
            visible={dfentitydialog}
            style={{
                width: "75%",
            }}
            header={`Assign Entity for " ` + selecteddfentity.title + ` "`}
            modal
            className="p-fluid"

            onHide={() => { setDfEntityDialog(false) }}
        >
            <div>
                <div className="col-12 grid">
                    <div className="col-6 ">
                        <div className='col-12 grid'>
                            <div className="flex align-items-center">
                                <Checkbox inputId="corporate" name="corporate" onChange={(e) => { updateDFEntity('tier0_ids', e.checked ? [0] : []) }} checked={selecteddfentity.tier0_ids.includes(0)} />
                                <label htmlFor="corporate" className="ml-2">Corporate</label>
                            </div>
                        </div>
                        <div className='col-12 grid align-items-center justify-content-between'>
                            <div className="col-3">
                                <label className="fs-16 fw-5">{label1}</label>
                            </div>
                            <div className="col-8">

                                <MultiSelect filter={true} className="w-full " panelClassName={'hidefilter'} optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selecteddfentity.tier1_ids} options={selectedloclist.tier1} onChange={(e) => { updateDFEntity('tier1_ids', e.value) }} />
                            </div>
                        </div>
                        <div className='col-12 grid align-items-center justify-content-between'>
                            <div className="col-3">
                                <label className="fs-16 fw-5">{label2}</label>
                            </div>
                            <div className="col-8">
                                <MultiSelect filter={true} className="w-full " panelClassName={'hidefilter'} optionLabel="name" multiple optionValue="id" style={{ width: '100%' }} value={selecteddfentity.tier2_ids} options={selectedloclist.tier2} onChange={(e) => { updateDFEntity('tier2_ids', e.value) }} />
                            </div>
                        </div>
                        <div className='col-12 grid align-items-center justify-content-between'>
                            <div className="col-3">
                                <label className="fs-16 fw-5">{label3}</label>
                            </div>
                            <div className="col-8">
                                <MultiSelect filter={true} className="w-full " panelClassName={'hidefilter'} optionLabel="name" multiple optionValue="id" style={{ width: '100%' }} value={selecteddfentity.tier3_ids} options={selectedloclist.tier3} onChange={(e) => { updateDFEntity('tier3_ids', e.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <ListBox options={getEntityOptions(selecteddfentity)} optionLabel="name"
                            optionGroupLabel="name" optionGroupChildren="items" listClassName="dis-option-hover" itemTemplate={itemTemplate} optionGroupTemplate={groupEntityTemplate} className="w-full" listStyle={{ height: '250px' }} />

                    </div>
                </div>
                <div className="col-12" style={{ padding: 15 }}>
                    <label className="col-12">Justification for selection of the above entities and for  non-assignment (if applicable) </label>

                    <div className="  flex justify-content-center">
                        <InputTextarea style={{ height: 150, overflow: 'auto', resize: 'none' }} value={selecteddfentity.comments} autoResize={false} onChange={(e) => { updateDFEntity('comments', e.target.value) }} />
                    </div>


                </div>
                <div className="flex justify-content-end">
                    <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { AssignDFEntity() }}>Assign Entity</Button>
                </div>

            </div>
        </Dialog>
        <Dialog
            visible={dfenitityviewdialog}
            style={{
                width: "75%",
            }}
            header={`Assign Entity User for " ` + selecteddfentity.title + ` "`}
            modal
            className="p-fluid"

            onHide={() => { setDfEnitityViewDialog(false) }}
        >
            <div>
                <DataTable value={dfEntityToUserAssignment([selecteddfentity]).map((i) => { return getRf(i) }).filter(i => i !== null)} scrollable rows={10}  >

                    <Column header="Entity" field='locationId' body={coverageTemplate} />
                    <Column alignHeader={'center'} header="Frequency" field='frequency' body={frequencyTemplate} />
                    <Column alignHeader={'center'} header="Start Date" field='start_date' body={startDateTemplate} />
                    <Column alignHeader={'center'} header="End Date" field='end_date' body={endDateTemplate} />
                    <Column alignHeader={'center'} header="Reporter" field='reporter_ids' body={reporterListTemplate} />
                    <Column alignHeader={'center'} header="Reviewer" field='reviewer_ids' body={reviewerListTemplate} />
                    <Column alignHeader={'center'} header="Approver" field='approver_ids' body={approverListTemplate} />
                    <Column alignHeader={'center'} header="Action" body={dfActionBodyTemplate} />
                </DataTable>

            </div>
        </Dialog>
        <Dialog
            visible={srfentitydialog}
            style={{
                width: "75%",
            }}
            header={`Assign Entity for " ` + selectedsrfentity.title + ` "`}
            modal
            className="p-fluid"

            onHide={() => { setSrfEntityDialog(false) }}
        >
            <div>
                <div className="col-12 grid">
                    <div className="col-6 ">
                        <div className='col-12 grid'>
                            <div className="flex align-items-center">
                                <Checkbox inputId="corporate" name="corporate" onChange={(e) => { updateSRFEntity('tier0_ids', e.checked ? [0] : []) }} checked={selectedsrfentity.tier0_ids.includes(0)} />
                                <label htmlFor="corporate" className="ml-2">Corporate</label>
                            </div>
                        </div>
                        <div className='col-12 grid align-items-center justify-content-between'>
                            <div className="col-3">
                                <label className="fs-16 fw-5">{label1}</label>
                            </div>
                            <div className="col-8">

                                <MultiSelect filter={true} className="w-full " panelClassName={'hidefilter'} optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selectedsrfentity.tier1_ids} options={selectedloclist.tier1} onChange={(e) => { updateSRFEntity('tier1_ids', e.value) }} />
                            </div>
                        </div>
                        <div className='col-12 grid align-items-center justify-content-between'>
                            <div className="col-3">
                                <label className="fs-16 fw-5">{label2}</label>
                            </div>
                            <div className="col-8">
                                <MultiSelect filter={true} className="w-full " panelClassName={'hidefilter'} optionLabel="name" multiple optionValue="id" style={{ width: '100%' }} value={selectedsrfentity.tier2_ids} options={selectedloclist.tier2} onChange={(e) => { updateSRFEntity('tier2_ids', e.value) }} />
                            </div>
                        </div>
                        <div className='col-12 grid align-items-center justify-content-between'>
                            <div className="col-3">
                                <label className="fs-16 fw-5">{label3}</label>
                            </div>
                            <div className="col-8">
                                <MultiSelect filter={true} className="w-full " panelClassName={'hidefilter'} optionLabel="name" multiple optionValue="id" style={{ width: '100%' }} value={selectedsrfentity.tier3_ids} options={selectedloclist.tier3} onChange={(e) => { updateSRFEntity('tier3_ids', e.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <ListBox options={getEntityOptions(selectedsrfentity)} optionLabel="name"
                            optionGroupLabel="name" optionGroupChildren="items" listClassName="dis-option-hover" itemTemplate={itemTemplate} optionGroupTemplate={groupEntityTemplate} className="w-full" listStyle={{ height: '250px' }} />

                    </div>
                </div>
                <div className="col-12" style={{ padding: 15 }}>
                    <label className="col-12">Justification for selection of the above entities and for  non-assignment (if applicable) </label>

                    <div className="  flex justify-content-center">
                        <InputTextarea style={{ height: 150, overflow: 'auto', resize: 'none' }} value={selectedsrfentity.comments} autoResize={false} onChange={(e) => { updateSRFEntity('comments', e.target.value) }} />
                    </div>


                </div>
                <div className="flex justify-content-end">
                    <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { AssignSRFEntity() }}>Assign Entity</Button>
                </div>

            </div>
        </Dialog>
        {/* <Dialog
            visible={dcfentityuserdialog}
            style={{
                width: "75%",
            }}
            header={"Assign User for Entity"}
            modal
            className="p-fluid"

            onHide={() => { setDcfEntityUserDialog(false) }}
        >
            <div>

                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10,
                            }}
                        >
                            Set Data Frequency
                        </label>
                    </div>
                    <div className="col-7 ddf">
                        <Dropdown disabled={dcfentityuserobj.id !== undefined} style={{ width: '100%', height: '100%' }} value={dcfentityuserobj.frequency} optionValue="id" options={frequency_list} onChange={(e) => updateDCFEntityUserAss("frequency", e.value)} optionLabel="name" placeholder="Select frequency" />

                    </div>



                </div>
                {submitted && (dcfentityuserobj.frequency === null) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Frequency
                        </small>
                    )}
                {stdlist.length !== 0 &&
                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="col-5">
                            <label
                                style={{
                                    margin: 10,
                                }}
                            >
                                Select Standard
                            </label>
                        </div>
                        <div className="col-7 ddf">
                            <Dropdown disabled={dcfentityuserobj.id !== undefined} showClear style={{ width: '100%' }} optionValue={'id'} value={dcfentityuserobj.standard} options={stdlist} onChange={(e) => { updateDCFEntityUserAss("standard", e.value) }} optionLabel="title" placeholder="Select Standard" />
                        </div>


                    </div>}
                {submitted && (dcfentityuserobj.standard === null) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Standard
                        </small>
                    )}
                {dcfentityuserobj.frequency !== null && <>
                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="col-5">
                            <label
                                style={{
                                    margin: 10,
                                }}
                            >
                                Start Date
                            </label>
                        </div>
                        <div className="col-7">
                            <ReactDatePicker
                                disabled={dcfentityuserobj.id !== undefined && dcfentityuserobj.start_date !== null}
                                selected={dcfentityuserobj.start_date}
                                onSelect={(e) => { updateDCFEntityUserAss("start_date", e) }}
                                renderMonthContent={renderMonthContent}
                                showMonthYearPicker
                                dateFormat="MM/yyyy"
                            />
                

                        </div>



                    </div>
                    {submitted && (dcfentityuserobj.start_date === null) &&
                        (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red", marginLeft: '22%'
                                }}
                            >

                                set start date
                            </small>
                        )}
                </>}
                {dcfentityuserobj.id !== undefined && dcfentityuserobj.start_date !== null && dcfentityuserobj.disabled !== true && <>
                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="col-5">
                            <label
                                style={{
                                    margin: 10,
                                }}
                            >
                                End Date
                            </label>
                        </div>
                        <div className="col-7">
                            <ReactDatePicker
                                disabled={((dcfentityuserobj.id === undefined || dcfentityuserobj.disable_end !== undefined))}
                                selected={dcfentityuserobj.end_date}
                                onSelect={(e) => { updateDCFEntityUserAss("end_date", e) }}
                                minDate={moment(dcfentityuserobj.start_date).toDate()}
                                showMonthYearPicker

                                dateFormat="MM/yyyy"
                            />
                          

                        </div>



                    </div>

                </>}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Reporter
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip"  value={dcfentityuserobj.reporter_ids} onChange={(e) => updateDCFEntityUserAss("reporter_ids", e.value)} options={userList} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Select Reporter" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>
                {submitted && (dcfentityuserobj.reporter_ids === null || dcfentityuserobj.reporter_ids.length === 0) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Atleast One Reporter
                        </small>
                    )}

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Reviewer
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip"  value={dcfentityuserobj.reviewer_ids} onChange={(e) => updateDCFEntityUserAss("reviewer_ids", e.value)} options={reviewerList} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Leave this as-is in case reporter is also the reviewer" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>


                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Approver
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip"  value={dcfentityuserobj.approver_ids} onChange={(e) => updateDCFEntityUserAss("approver_ids", e.value)} options={approverList} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Select Approver" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>
                {submitted && (dcfentityuserobj.approver_ids === null || dcfentityuserobj.approver_ids.length === 0) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Atleast One Approver
                        </small>
                    )}

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Comments for Justification
                        </label>
                    </div>
                    <div className="col-7">
                        <InputTextarea style={{ width: '100%', height: 200, overflow: 'auto' }} onChange={(e) => updateDCFEntityUserAss("comments", e.target.value)} />
                    </div>



                </div>
                <div className="flex justify-content-end">
                    <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { AssignDCFUserEntity() }}>Assign User </Button>
                </div>

            </div>
        </Dialog> */}
        <Dialog
            visible={dcfentityuserdialog}
            style={{
                width: "75%",
            }}
            header={"Assign User for Entity"}
            modal
            className="p-fluid"

            onHide={() => { setDcfEntityUserDialog(false) }}
        >
            <div>

                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10,
                            }}
                        >
                            Set Data Frequency
                        </label>
                    </div>
                    <div className="col-7 ddf">
                        <Dropdown disabled={dcfentityuserobj.id !== undefined} style={{ width: '100%', height: '100%' }} value={dcfentityuserobj.frequency} optionValue="id" options={frequency_list} onChange={(e) => updateDCFEntityUserAss("frequency", e.value)} optionLabel="name" placeholder="Select frequency" />

                    </div>



                </div>
                {submitted && (dcfentityuserobj.frequency === null) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Frequency
                        </small>
                    )}
                {stdlist.length !== 0 &&
                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="col-5">
                            <label
                                style={{
                                    margin: 10,
                                }}
                            >
                                Select Standard
                            </label>
                        </div>
                        <div className="col-7 ddf">
                            <Dropdown disabled={dcfentityuserobj.id !== undefined} showClear style={{ width: '100%' }} optionValue={'id'} value={dcfentityuserobj.standard} options={stdlist} onChange={(e) => { updateDCFEntityUserAss("standard", e.value) }} optionLabel="title" placeholder="Select Standard" />
                        </div>


                    </div>}
                {submitted && (dcfentityuserobj.standard === null) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Standard
                        </small>
                    )}
                {dcfentityuserobj.frequency !== null && <>
                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="col-5">
                            <label
                                style={{
                                    margin: 10,
                                }}
                            >
                                Start Date
                            </label>
                        </div>
                        <div className="col-7">
                            <ReactDatePicker
                                minDate={moment(minDate).add(1, 'month').toDate()}
                                maxDate={DateTime.utc().toJSDate()}
                                disabled={dcfentityuserobj.id !== undefined && dcfentityuserobj.start_date !== null}
                                selected={dcfentityuserobj.start_date}
                                onSelect={(e) => { updateDCFEntityUserAss("start_date", e) }}
                                renderMonthContent={renderMonthContent}
                                showMonthYearPicker
                                dateFormat="MM/yyyy"
                            />


                        </div>



                    </div>
                    {submitted && (dcfentityuserobj.start_date === null) &&
                        (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red", marginLeft: '22%'
                                }}
                            >

                                set start date
                            </small>
                        )}
                </>}
                {dcfentityuserobj.id !== undefined && dcfentityuserobj.start_date !== null && dcfentityuserobj.disabled !== true && <>
                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="col-5">
                            <label
                                style={{
                                    margin: 10,
                                }}
                            >
                                End Date
                            </label>
                        </div>
                        <div className="col-7">
                            <ReactDatePicker
                                disabled={((dcfentityuserobj.disable_end !== undefined))}
                                selected={dcfentityuserobj.end_date}
                                onSelect={(e) => { updateDCFEntityUserAss("end_date", e) }}
                                minDate={moment(dcfentityuserobj.start_date).toDate()}
                                showMonthYearPicker
                                maxDate={DateTime.utc().toJSDate()}
                                dateFormat="MM/yyyy"
                            />


                        </div>



                    </div>

                </>}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Reporter
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip" value={dcfentityuserobj.reporter_ids} onChange={(e) => updateDCFEntityUserAss("reporter_ids", e.value)} options={userList} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Select Reporter" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>
                {submitted && (dcfentityuserobj.reporter_ids === null || dcfentityuserobj.reporter_ids.length === 0) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Atleast One Reporter
                        </small>
                    )}

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Reviewer
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip" value={dcfentityuserobj.reviewer_ids} onChange={(e) => updateDCFEntityUserAss("reviewer_ids", e.value)} options={reviewerList} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Leave this as-is in case reporter is also the reviewer" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>


                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Approver
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip" value={dcfentityuserobj.approver_ids} onChange={(e) => updateDCFEntityUserAss("approver_ids", e.value)} options={approverList} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Select Approver" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>
                {submitted && (dcfentityuserobj.approver_ids === null || dcfentityuserobj.approver_ids.length === 0) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Atleast One Approver
                        </small>
                    )}


                <div className="flex justify-content-end">
                    <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { AssignDCFUserEntity() }}>Assign User </Button>
                </div>

            </div>
        </Dialog>
        <Dialog
            visible={dfentityuserdialog}
            style={{
                width: "75%",
            }}
            header={"Assign User for Entity"}
            modal
            className="p-fluid"

            onHide={() => { setDfEntityUserDialog(false) }}
        >
            <div>

                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10,
                            }}
                        >
                            Set Data Frequency
                        </label>
                    </div>
                    <div className="col-7 ddf">
                        <Dropdown disabled={dfentityuserobj.id !== undefined} style={{ width: '100%', height: '100%' }} value={dfentityuserobj.frequency} optionValue="id" options={frequency_list} onChange={(e) => updateDFEntityUserAss("frequency", e.value)} optionLabel="name" placeholder="Select frequency" />

                    </div>



                </div>
                {submitted && (dfentityuserobj.frequency === null) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Frequency
                        </small>
                    )}

                {dfentityuserobj.frequency !== null && <>
                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="col-5">
                            <label
                                style={{
                                    margin: 10,
                                }}
                            >
                                Start Date
                            </label>
                        </div>
                        <div className="col-7">
                            <ReactDatePicker
                                minDate={moment(minDate).add(1, 'month').toDate()}
                                maxDate={DateTime.utc().toJSDate()}
                                disabled={dfentityuserobj.id !== undefined && dfentityuserobj.start_date !== null}
                                selected={dfentityuserobj.start_date}
                                onSelect={(e) => { updateDFEntityUserAss("start_date", e) }}
                                renderMonthContent={renderMonthContent}
                                showMonthYearPicker
                                dateFormat="MM/yyyy"
                            />


                        </div>



                    </div>
                    {submitted && (dfentityuserobj.start_date === null) &&
                        (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red", marginLeft: '22%'
                                }}
                            >

                                set start date
                            </small>
                        )}
                </>}
                {dfentityuserobj.id !== undefined && dfentityuserobj.start_date !== null && dfentityuserobj.disabled !== true && <>
                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="col-5">
                            <label
                                style={{
                                    margin: 10,
                                }}
                            >
                                End Date
                            </label>
                        </div>
                        <div className="col-7">
                            <ReactDatePicker
                                disabled={((dfentityuserobj.disable_end !== undefined))}
                                selected={dfentityuserobj.end_date}
                                onSelect={(e) => { updateDFEntityUserAss("end_date", e) }}
                                minDate={moment(dfentityuserobj.start_date).toDate()}
                                showMonthYearPicker
                                maxDate={DateTime.utc().toJSDate()}
                                dateFormat="MM/yyyy"
                            />


                        </div>



                    </div>

                </>}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Reporter
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip" value={dfentityuserobj.reporter_ids} onChange={(e) => updateDFEntityUserAss("reporter_ids", e.value)} options={userList} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Select Reporter" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>
                {submitted && (dfentityuserobj.reporter_ids === null || dfentityuserobj.reporter_ids.length === 0) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Atleast One Reporter
                        </small>
                    )}

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Reviewer
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip" value={dfentityuserobj.reviewer_ids} onChange={(e) => updateDFEntityUserAss("reviewer_ids", e.value)} options={reviewerList} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Leave this as-is in case reporter is also the reviewer" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>


                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Approver
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip" value={dfentityuserobj.approver_ids} onChange={(e) => updateDFEntityUserAss("approver_ids", e.value)} options={approverList} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Select Approver" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>
                {submitted && (dfentityuserobj.approver_ids === null || dfentityuserobj.approver_ids.length === 0) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Atleast One Approver
                        </small>
                    )}


                <div className="flex justify-content-end">
                    <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { AssignDFUserEntity() }}>Assign User </Button>
                </div>

            </div>
        </Dialog>
        <Dialog
            visible={srfentityuserdialog}
            style={{
                width: "75%",
            }}
            header={"Assign User for Entity"}
            modal
            className="p-fluid"

            onHide={() => { setSrfEntityUserDialog(false) }}
        >
            <div>

                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10,
                            }}
                        >
                            Set Data Frequency
                        </label>
                    </div>
                    <div className="col-7 ddf">
                        <Dropdown disabled={srfentityuserobj.id !== undefined} style={{ width: '100%', height: '100%' }} value={srfentityuserobj.frequency} optionValue="id" options={frequency_list} onChange={(e) => updateSRFEntityUserAss("frequency", e.value)} optionLabel="name" placeholder="Select frequency" />

                    </div>



                </div>
                {submitted && (srfentityuserobj.frequency === null) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Frequency
                        </small>
                    )}

                {srfentityuserobj.frequency !== null && <>
                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="col-5">
                            <label
                                style={{
                                    margin: 10,
                                }}
                            >
                                Start Date
                            </label>
                        </div>
                        <div className="col-7">
                            <ReactDatePicker
                                minDate={moment(minDate).add(1, 'month').toDate()}
                                maxDate={DateTime.utc().toJSDate()}
                                disabled={srfentityuserobj.id !== undefined && srfentityuserobj.start_date !== null}
                                selected={srfentityuserobj.start_date}
                                onSelect={(e) => { updateSRFEntityUserAss("start_date", e) }}
                                renderMonthContent={renderMonthContent}
                                showMonthYearPicker
                                dateFormat="MM/yyyy"
                            />


                        </div>



                    </div>
                    {submitted && (srfentityuserobj.start_date === null) &&
                        (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red", marginLeft: '22%'
                                }}
                            >

                                set start date
                            </small>
                        )}
                </>}
                {srfentityuserobj.id !== undefined && srfentityuserobj.start_date !== null && srfentityuserobj.disabled !== true && <>
                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="col-5">
                            <label
                                style={{
                                    margin: 10,
                                }}
                            >
                                End Date
                            </label>
                        </div>
                        <div className="col-7">
                            <ReactDatePicker
                                disabled={((srfentityuserobj.disable_end !== undefined))}
                                selected={srfentityuserobj.end_date}
                                onSelect={(e) => { updateSRFEntityUserAss("end_date", e) }}
                                minDate={moment(srfentityuserobj.start_date).toDate()}
                                showMonthYearPicker
                                maxDate={DateTime.utc().toJSDate()}
                                dateFormat="MM/yyyy"
                            />


                        </div>



                    </div>

                </>}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Reporter
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip" value={srfentityuserobj.reporter_ids} onChange={(e) => updateSRFEntityUserAss("reporter_ids", e.value)} options={supplierList} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Select Reporter" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>
                {submitted && (srfentityuserobj.reporter_ids === null || srfentityuserobj.reporter_ids.length === 0) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Atleast One Reporter
                        </small>
                    )}

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Reviewer
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip" value={srfentityuserobj.reviewer_ids} onChange={(e) => updateSRFEntityUserAss("reviewer_ids", e.value)} options={reviewerList} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Leave this as-is in case reporter is also the reviewer" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>


                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Approver
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip" value={srfentityuserobj.approver_ids} onChange={(e) => updateSRFEntityUserAss("approver_ids", e.value)} options={approverList} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Select Approver" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>
                {submitted && (srfentityuserobj.approver_ids === null || srfentityuserobj.approver_ids.length === 0) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Atleast One Approver
                        </small>
                    )}


                <div className="flex justify-content-end">
                    <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { AssignSRFUserEntity() }}>Assign User </Button>
                </div>

            </div>
        </Dialog>
        <Dialog
            visible={allentityuserdialog}
            style={{
                width: "75%",
            }}
            header={"Entity"}
            modal
            className="p-fluid"

            onHide={() => { setAllEntityUserDialog(false) }}
        >
            <div>

                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10,
                            }}
                        >
                            Set Data Frequency
                        </label>
                    </div>
                    <div className="col-7 ddf">
                        <Dropdown disabled={true} style={{ width: '100%', height: '100%' }} value={allentityuserobj.frequency} optionValue="id" options={frequency_list} onChange={(e) => updateDCFEntityUserAss("frequency", e.value)} optionLabel="name" placeholder="Select frequency" />

                    </div>



                </div>
                {submitted && (allentityuserobj.frequency === null) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Frequency
                        </small>
                    )}

                {allentityuserobj.frequency !== null && <>
                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="col-5">
                            <label
                                style={{
                                    margin: 10,
                                }}
                            >
                                Start Date
                            </label>
                        </div>
                        <div className="col-7">
                            <ReactDatePicker
                                minDate={moment(minDate).add(1, 'month').toDate()}
                                maxDate={DateTime.utc().toJSDate()}
                                disabled={true}
                                selected={allentityuserobj.start_date}
                                onSelect={(e) => { updateDCFEntityUserAss("start_date", e) }}
                                renderMonthContent={renderMonthContent}
                                showMonthYearPicker
                                dateFormat="MM/yyyy"
                            />


                        </div>



                    </div>
                    {submitted && (allentityuserobj.start_date === null) &&
                        (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red", marginLeft: '22%'
                                }}
                            >

                                set start date
                            </small>
                        )}
                </>}
                {allentityuserobj.id !== undefined && allentityuserobj.end_date !== null && <>
                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="col-5">
                            <label
                                style={{
                                    margin: 10,
                                }}
                            >
                                End Date
                            </label>
                        </div>
                        <div className="col-7">
                            <ReactDatePicker
                                disabled={true}
                                selected={allentityuserobj.end_date}
                                onSelect={(e) => { updateDCFEntityUserAss("end_date", e) }}
                                minDate={moment(allentityuserobj.start_date).toDate()}
                                showMonthYearPicker
                                maxDate={DateTime.utc().toJSDate()}
                                dateFormat="MM/yyyy"
                            />


                        </div>



                    </div>

                </>}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Reporter
                        </label>
                    </div>
                    <div className="col-7">

                        <MultiSelect display="chip" value={allentityuserobj.reporter_ids} selectAll={false} options={allentityuserobj.srfId !== undefined ? supplierList.filter(i => allentityuserobj.reporter_ids.includes(i.id)) : userList.filter(i => allentityuserobj.reporter_ids.includes(i.id))} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Select Reporter" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>
                {submitted && (allentityuserobj.reporter_ids === null || allentityuserobj.reporter_ids.length === 0) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Atleast One Reporter
                        </small>
                    )}

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Reviewer
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip" value={allentityuserobj.reviewer_ids} selectAll={false} options={reviewerList.filter(i => allentityuserobj.reviewer_ids.includes(i.id))} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Leave this as-is in case reporter is also the reviewer" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>


                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label
                            style={{
                                margin: 10
                            }}
                        >
                            Assign Approver
                        </label>
                    </div>
                    <div className="col-7">
                        <MultiSelect display="chip" value={allentityuserobj.approver_ids} selectAll={false} options={approverList.filter(i => allentityuserobj.approver_ids.includes(i.id))} optionLabel="name" optionValue="id"
                            filter={true} placeholder="Select Approver" className="w-full " panelClassName={'hidefilter'} />

                    </div>



                </div>
                {submitted && (allentityuserobj.approver_ids === null || allentityuserobj.approver_ids.length === 0) &&
                    (
                        <small
                            className="p-invalid"
                            style={{
                                color: "red", marginLeft: '22%'
                            }}
                        >

                            Select Atleast One Approver
                        </small>
                    )}


                <div className="flex justify-content-end">
                    <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { setAllEntityUserDialog(false) }}>Close</Button>
                </div>

            </div>
        </Dialog>
    </>)
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AssignDcfToUser, comparisonFn);