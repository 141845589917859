import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoggedUserDetail } from './RTK/Login/userProfile';
import { motion } from "framer-motion";
import { resetOverallPendingData } from './RTK/Background/pendingData';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import useForceUpdate from 'use-force-update';
export const AppTopbar = (props) => {
    const selector = useSelector(state => state.user.userdetail)
    const [rotation, setRotation] = useState(true)
    const history = useHistory()
    const dispatch = useDispatch()
    const login_data = useSelector((state) => state.user.userdetail)
    const location = useLocation()
    const navigate = useHistory()
    const forceUpdate = useForceUpdate()
    const client_info = useSelector((state) => state.userlist.admindetail)
    const [pathname, setPathname] = useState(null)
    useEffect(() => {
        setPathname(location.pathname)

    }, [location.pathname])
    return (
        <div className="layout-topbar justify-content-between">
                    <div className=' flex align-items-center justify-content-start'></div>
            <div className='grid'>
                <div className='flex justify-content-end align-items-center mr-3' style={{ flexDirection: 'column' }}>
                    <img id='clientlogo' src={require('../src/assets/images/stt-logo.svg').default} style={{
                        width: '100%', maxWidth: 200, padding: 10

                    }} />

                </div>

            </div>

            <hr style={{borderRight: '2px solid lightgray',
height: '50%'}} />
  <div className='col-4 fw-7 fs-18'>
                Environmental, Social and Governance
                </div>
     
            <div className='col-6 flex align-items-center justify-content-between'>
            {login_data.role === 'clientadmin' &&
                    <div className='ml-2 grid'>
                        <div className='m-2'> <label onClick={() => { navigate.push({ pathname: '/client_home' }) }} onMouseLeave={(e) => { e.target.style.textDecoration = (pathname !== '/client_home' && 'none'); }} onMouseOver={(e) => { e.target.style.textDecoration = 'underline' }} className={(pathname === '/client_home' ? 'clr-navy text-bold  text-link ' : 'bg-white text-none ') + 'fs-16  br-5 p-3 cur-pointer '} >Home</label> </div>
                        <div className='m-2'><label onClick={() => { navigate.push({ pathname: '/report_list' }) }} onMouseLeave={(e) => { e.target.style.textDecoration = (pathname !== '/report_list' && 'none'); }} onMouseOver={(e) => { e.target.style.textDecoration = 'underline' }} className={(pathname === '/report_list' ? 'clr-navy text-bold  text-link ' : 'bg-white text-none ') + 'fs-16  br-5 p-3 cur-pointer'}>Reports & Disclosure</label> </div>
                        <div className='m-2'> <label className='fs-16 br-5 clr-gray-3 p-3 cur-pointer' >Admin</label> </div>
                    </div>
                }
          
                <Link style={{ width: 200 }} className="justify-content-center layout-topbar-logo mr-2">
                    <img id='logo' width={'100%'} height={'auto'} src={props.layoutColorMode === 'light' ? require('../src/assets/images/eisqr_logo_final_v1.png').default : require('../src/assets/images/eisqr_logo.png').default} alt="logo" />

                </Link>



            </div>




        </div>
    );
}
