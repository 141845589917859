
import Axios from "axios";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { API } from "../components/constants/api_url";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import LazyView from "../components/LazyView";
import { Checkbox } from "primereact/checkbox";
import { MdSettingsRemote } from "react-icons/md";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { data } from "jquery";
import moment from "moment";
import Swal from "sweetalert2";
import { InputTextarea } from "primereact/inputtextarea";
import APIServices from "../service/APIService";
const ClientSupplierDCFAssignment = () => {
    const [dcflist, setDCFList] = useState([])
    const [assignment, setAssigment] = useState([])
    const [tagsdialog, setTagsDialog] = useState(false);
    const [index, setIndex] = useState(null)
    const [submitted, setSubmitted] = useState(false);
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Annually', id: 4 }, { name: '6-Monthly', id: 5 }, { name: 'Undefined', id: 6 }]
    const [supplierlist, setSupplierList] = useState([])
    const [supplier, setSupplier] = useState({ supplier_id: null, config: [], comments: [''] })
    const [assigndcf, setAssignDCF] = useState({ frequency: null, start_date: null })
    const [assigndialog, setAssignDialog] = useState(false)
    const [configdialog, setConfigDialog] = useState(false)




    const forceUpdate = useForceUpdate()
    const selector = useSelector((state) => state.user.userdetail)


    useEffect(() => {

        let dcf_list = [], supplier_list = []
        APIServices.get( API.DCF).then((res) => {

            APIServices.get(API.AssignDCFClient_UP(selector.id)).then((res2) => {
                if (res2.data.length !== 0) {
                    res.data.forEach((i) => {

                        if (res2.data[0].data1 !== null && res2.data[0].data1.length !== 0) {
                            if (res2.data[0].data1[0].dcf_ids.findIndex((k) => { return k === i.id }) !== -1) {
                                dcf_list.push(i)

                            }
                        }
                    })

                    setDCFList(dcf_list);
                    forceUpdate()
                }
            })

        })

        APIServices.get(API.UserProfile).then((res) => {
            let data = res.data.filter((profile) => { return profile.information.cid === selector.id && profile.role === 'clientsupplier' })
            setSupplierList(data)

            supplier_list = data

        })

        setTimeout(() => {
            APIServices.get(API.AssignDCFSupplier_UP(selector.id)).then((res) => {

                let suplist = []
                res.data.forEach((item1) => {

                    let assigned = [];
                    let indx = supplier_list.findIndex((i) => { return i.id === item1.supplier_id })
                    if (indx !== -1) {
                        suplist.push(supplier_list[indx].id)
                        item1.supplier_id = supplier_list[indx]
                        item1.config.map((item, i) => {
                            let index = dcf_list.findIndex((k) => { return k.id === item.id });
                            if (index !== -1) {

                                assigned.push({ ...item, ...dcf_list[index], selected: true })

                            }
                        })
                        item1.config = assigned

                    }

                })

                supplier_list.forEach((item) => {
                    if (suplist.includes(item.id)) {
                        item.disabled = true
                    }
                })
                console.log(supplier_list)
                setSupplierList(supplier_list)
                setAssigment(res.data)
            })

        }, 500)

    }, [])
    const addNewAssign = () => {
        setAssignDialog(true);
        setSubmitted(false)
        setSupplier({ supplier_id: null, config: [], comments: [''] })
        let loc = JSON.parse(JSON.stringify(dcflist))
        loc.map((item) => {
            item.selected = false;
            delete item.config
        })
        setDCFList(loc);
        forceUpdate()

    }
    const updateDCFConfig = (obj, val) => {
        let loc = JSON.parse(JSON.stringify(assigndcf));
        loc[obj] = val;
        console.log(loc)

        setAssignDCF(loc);
        forceUpdate()
    }
    const updateSupplier = (obj, val, e) => {

        let loc = supplier;
        if (obj === 'comments') {
            loc[obj] = [val, moment.utc()];
        } else {
            if (obj === 'supplier_id') {
                let locass = JSON.parse(JSON.stringify(assignment))
                let indsup = locass.findIndex((k) => { return k.supplier_id.id === val })
                if (indsup !== -1) {
                    console.log(locass[indsup])
                    editAssignment(locass[indsup])

                }

            }
            loc[obj] = val;
        }


        setSupplier(loc);
        forceUpdate()
    }
    const configItem = (item, index) => {
        setIndex(index)

        if (item.config === undefined) {
            setAssignDCF({ frequency: null, start_date: null })
            setConfigDialog(true)
        } else {

            setAssignDCF(() => item.config)
            setConfigDialog(true)

        }
        forceUpdate()

    }
    const checkItem = (item, index) => {
        setIndex(index)
        if (item.selected === true) {
            item.selected = false;
            delete item.config;
        } else {
            if (item.config === undefined) {
                setAssignDCF({ frequency: null, start_date: null })
                setConfigDialog(true)
            } else {
                setAssignDCF(item.config)
                setConfigDialog(true)

            }
        }
    }
    const saveDCFConfig = () => {

        if (assigndcf.frequency !== null && assigndcf.frequency !== null) {
            let loc = JSON.parse(JSON.stringify(dcflist));
            let config = JSON.parse(JSON.stringify(assigndcf));

            loc[index]['selected'] = true
            loc[index]['config'] = config;
            setDCFList(loc);
            setConfigDialog(false);
            setIndex(null)
        }
    }
    const assignmentTemplate = (rowData) => {
        console.log(rowData)
        return rowData.config.map((item) => {
            console.log(item)
            return (
                <div>
                    {item.title}
                </div>
            )
        })
    }
    const frequencyTemplate = (rowData) => {

        return rowData.config.map((item) => {

            return (
                <div>
                    {frequency_list[item.config.frequency - 1].name}
                </div>
            )
        })
    }
    const startDateTemplate = (rowData) => {
        return rowData.config.map((item) => {
            console.log(item)
            return (
                <div>
                    {moment.utc(item.config.start_date).local().format('MMM/YYYY')}
                </div>
            )
        })
    }
    const deleteAssignment = async (item) => {
        let suplist = JSON.parse(JSON.stringify(supplierlist))
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete the  "${item.supplier_id.information.empname}" assignment</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Delete',

        })
        if (accept) {
            let locass = JSON.parse(JSON.stringify(assignment))

            let inddel = locass.findIndex((k) => { return k.supplier_id.id === item.supplier_id.id })
            if (inddel !== -1) {
                APIServices.delete(API.DCF_Supplier_Edit(item.id)).then((res) => {
                    locass.splice(inddel, 1)
                    setAssigment(locass);
                    forceUpdate()
                    suplist.forEach((items) => {
                        if (items.id === item.supplier_id.id) {
                            items.disabled = false
                        }
                    })
                    setSupplierList(suplist)
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Assignment Deleted Successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
            }
        } else {
            console.log(item)
        }
    }
    const actionBodyTemplate = (rowData) => {

        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, background: 'dodgerblue' }}
                        onClick={() => {
                            editAssignment(rowData)
                        }}
                    />

                    <Button
                        icon="pi pi-trash"
                        className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'palevioletred' }}
                        onClick={() => {
                            deleteAssignment(rowData)
                        }}
                    />
                </div>
            </>
        )
    }
    const editAssignment = (item) => {
        let loc = JSON.parse(JSON.stringify(dcflist))

        item.config.forEach((items) => {
            let index = loc.findIndex((k) => { return k.id === items.id })
            if (index !== -1) {
                items.config.start_date = moment.utc(items.config.start_date).toDate();
                loc[index]['selected'] = true
                loc[index]['config'] = items.config

            }
        })

        setSupplier(item)
        setDCFList(loc);
        console.log(loc)
        forceUpdate()
        !assigndialog && setAssignDialog(true)
    }
    const saveDCF = () => {
        setSubmitted(true)
        let loc = JSON.parse(JSON.stringify(dcflist))
        let suplist = JSON.parse(JSON.stringify(supplierlist))
        let data = { supplier_id: supplier.supplier_id.id, config: [], comments: supplier.comments }
        loc.forEach((item) => {
            if (item.selected) {
                data.config.push({ id: item.id, config: item.config })
            }
        })

        data['dfcs'] = data.config.map((k) => { return k.id })
        if (data.supplier_id !== null, data.dfcs.length !== 0 && data.comments[0].trim().length !== 0) {
            if (supplier.id === undefined) {
                data['supplier_id'] = supplier.supplier_id
                data['created_on'] = moment.utc()
                data['creator_id'] = selector.id

                APIServices.post(API.AssignDCFSupplier_UP(selector.id), data).then((res) => {
                    let assigned = []
                    data['supplier_id'] = supplierlist.filter((j) => { return j.id === data.supplier_id })[0]
                    let locass = JSON.parse(JSON.stringify(assignment))
                    data.config.map((item) => {
                        let index = loc.findIndex((k) => { return k.id === item.id });
                        if (index !== -1) {
                            assigned.push({ ...item, ...loc[index], selected: true })

                        }
                    })
                    data.config = assigned
                    data.id = res.data.id
                    locass.push(data)
                    setAssigment(locass)

                    suplist.forEach((item) => {
                        if (data.supplier_id.id === item.id) {
                            item.disabled = true
                        }
                    })
                    setSupplierList(suplist)
                    setSupplier({ supplier_id: null, config: [], comments: [''] })

                    forceUpdate();
                    setAssignDialog(false)
                })

            } else {
                data['modified_on'] = moment.utc()
                data['modifier_id'] = selector.id
                console.log(data, 'submiss')
                APIServices.patch(API.DCF_Supplier_Edit(supplier.id), data).then((res) => {
                    let locass = JSON.parse(JSON.stringify(assignment))
                    let index = locass.findIndex((k) => { return k.supplier_id.id === supplier.supplier_id.id })
                    let assigned = []
                    suplist.forEach((item) => {
                        if (data.supplier_id.id === supplier.supplier_id.id) {
                            item.disabled = true
                        }
                    })
                    setSupplierList(suplist)
                    if (index !== -1) {
                        data.config.map((item) => {
                            let index = loc.findIndex((k) => { return k.id === item.id });
                            if (index !== -1) {
                                assigned.push({ ...item, ...loc[index], selected: true })

                            }
                        })
                        locass[index].config = assigned;

                        setAssigment(locass)

                    }


                    setSupplier({ supplier_id: null, config: [], comments: [''] })
                    setAssignDialog(false)
                })

            }

        }

    }
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card" style={{ height: 'calc(100vh - 9rem)' }}>
                    <div style={{
                        fontSize: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: '600', marginBottom: 10
                    }}>Supplier DCF Assignment</div>
                    <div>
                        <div className="col-12" style={{ display: 'flex', flexDirection: 'row' }} >
                            <div className='col-6' >
                                <div style={{
                                    padding: 10,
                                    background: 'gainsboro',
                                    borderRadius: '10px',
                                    flexDirection: 'column',
                                    display: 'flex',
                                    color: 'black',


                                }} >

                                    <label style={{ margin: 5 }}>No. of Suppliers included in platform : {supplierlist.length}</label>

                                </div>
                            </div>
                            <div className='col-6' >
                                <div style={{
                                    padding: 10,
                                    background: 'gainsboro',
                                    borderRadius: '10px',
                                    flexDirection: 'column',
                                    display: 'flex',
                                    color: 'black',

                                }} >
                                    <label style={{ margin: 5 }}>No. of Suppliers with DCF Assignment : {assignment.length}</label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-12" style={{ display: 'flex', justifyContent: 'flex-end' }} >
                        <Button onClick={() => { addNewAssign() }}> + Assign DCF</Button>
                    </div>
                    <div>
                        <DataTable
                            value={assignment}
                            style={{
                                padding: 10,
                                height: 105
                            }}
                            paginator
                            rows={20}
                            rowsPerPageOptions={[10,20,50,100]}

                            responsiveLayout="scroll"
                        >


                            <Column field="baseValue" body={(rowData) => { return (<span>{rowData.supplier_id.information.empname}</span>) }} header="Supplier"> </Column>
                            <Column field="baseValue" body={assignmentTemplate} header="Assignment"> </Column>
                            <Column field="baseValue" body={startDateTemplate} header="Start Date"> </Column>
                            <Column field="baseValue" body={frequencyTemplate} header="Frequency"> </Column>
                            <Column body={actionBodyTemplate} />

                        </DataTable>
                    </div>
                </div>
            </div>
            <Dialog
                visible={assigndialog}
                style={{
                    width: "50%",
                }}
                header={supplier.id === undefined ? "Assign DCF" : 'Edit "' + supplier.supplier_id.information.empname + '" assignment'}
                modal
                className="p-fluid"

                onHide={() => { setAssignDialog(false); setSupplier({ supplier_id: null, config: [], comments: [''] }) }}
            >
                {supplier.id === undefined && <div className="col-12" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="col-5">
                        <label style={{
                            margin: 10,
                        }}> Select Supplier</label>
                    </div>

                    <div className="col-7">
                        <Dropdown options={supplierlist} optionLabel={'information.empname'} optionValue={'id'} value={supplier.supplier_id} onChange={(e) => { updateSupplier('supplier_id', e.value, e) }} style={{ width: '100%', height: '100%' }} />
                    </div>
                </div>}
                {(supplier.supplier_id !== null || supplier.id !== undefined) &&
                    <div>
                        <div style={{ maxHeight: 350, overflow: 'scroll' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {dcflist.map((item, index) => {
                                    console.log(item)
                                    return (
                                        <LazyView duration={0.5}  >
                                            <div key={item.id} style={{
                                                padding: '10px',
                                                background: '#005284',
                                                borderRadius: '10px', padding: 10, justifyContent: 'space-between',
                                                margin: '10px', color: 'white'
                                            }} className="flex align-items-center">
                                                <div  >
                                                    <Checkbox style={{ background: 'green' }} onChange={() => { checkItem(item, index) }} inputId={item.id} name="category" value={item.title} checked={item.selected} />
                                                    {/* <div style={{position:'absolute',left:-10}}> <label>{item.suffix}</label> </div>  */}
                                                    <label htmlFor={item.id} onClick={() => { checkItem(item, index) }} className="ml-2">{item.title}</label>
                                                </div>
                                                <i className="material-icons" style={{ color: 'white', cursor: 'pointer' }} onClick={() => { configItem(item, index) }} >settings</i>
                                            </div>
                                        </LazyView>
                                    )
                                })

                                }
                            </div>
                        </div>
                        <div className="col-12">
                            <label>Comments <span style={{ color: 'red' }}>*</span></label>
                            <InputTextarea value={supplier.comments[0]} onChange={(e) => { updateSupplier('comments', e.target.value) }} />

                        </div>
                        {submitted && supplier.comments[0].trim().length === 0 &&
                            <small style={{ color: 'red' }}>
                                Comments required
                            </small>

                        }
                        <div>
                            <Button label='Save & Exit' style={{ marginTop: 15 }} onClick={() => { saveDCF() }}></Button>

                        </div>


                    </div>}

            </Dialog>
            <Dialog
                visible={configdialog}
                style={{
                    width: "50%",
                }}
                header={"Configure Assignment"}
                modal
                className="p-fluid"

                onHide={() => { setConfigDialog(false) }}
            >
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div className="col-5">
                                <label
                                    style={{
                                        margin: 10,
                                    }}
                                >
                                    Start Date
                                </label>
                            </div>
                            <div className="col-7">
                                <Calendar dateFormat="mm/yy" view='month' value={assigndcf.start_date !== null ? moment.utc(assigndcf.start_date).toDate() : assigndcf.start_date} placeholder="date" onChange={(e) => {
                                    updateDCFConfig("start_date", e.value);
                                }} />

                            </div>



                        </div>
                        {submitted && (assigndcf.start_date === null) &&
                            (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red", marginLeft: '22%'
                                    }}
                                >

                                    set start date
                                </small>
                            )}
                        <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div className="col-5">
                                <label
                                    style={{
                                        margin: 10,
                                    }}
                                >
                                    Set Data Frequency
                                </label>
                            </div>
                            <div className="col-7 ddf">
                                <Dropdown onFocus={() => { setTimeout(() => { document.getElementsByClassName('p-dropdown-items-wrapper')[0] !== null && document.getElementsByClassName('p-dropdown-items-wrapper')[0].setAttribute('style', 'height:auto') }, 100) }} style={{ width: '100%', height: '100%' }} value={assigndcf.frequency} options={frequency_list} optionValue='id' onChange={(e) => updateDCFConfig("frequency", e.value)} optionLabel="name" placeholder="Select frequency" />

                            </div>



                        </div>
                        {submitted && (assigndcf.frequency === null) &&
                            (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red", marginLeft: '22%'
                                    }}
                                >

                                    Select Frequency
                                </small>
                            )}
                        <div>
                            <Button label='Save & Exit' onClick={() => { saveDCFConfig() }}></Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ClientSupplierDCFAssignment, comparisonFn);
