
import APIServices from "./APIService"
import { resetLoggedUserDetail } from "../RTK/Login/userProfile";
import { resetOverallPendingData } from "../RTK/Background/pendingData";



const Interceptor = (store) => {

    APIServices.interceptors.request.use(
        config => {
            config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
            return config;
        }, error => {
            return Promise.reject(error)
        }
    )
    APIServices.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            let res = error.response;

            if (res.status === 401) {
                store.dispatch(resetLoggedUserDetail());
                store.dispatch(resetOverallPendingData([]));
             
                window.location.href = '/#/';
                window.location.reload()

            }
            return Promise.reject(error)
        }

    )
}

export default Interceptor