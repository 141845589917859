import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useSelector } from 'react-redux';
import APIServices from '../../../service/APIService';
import { API } from '../../../components/constants/api_url';
import { TabMenu } from 'primereact/tabmenu';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import useForceUpdate from 'use-force-update';
import Swal from 'sweetalert2';

const UserListing = () => {

    const [list1, setList1] = useState([])
    const [list2, setList2] = useState([])
    const [list3, setList3] = useState([])
    const [search1, setSearch1] = useState('')
    const [search2, setSearch2] = useState('')
    const [search3, setSearch3] = useState('')
    const [roledialog, setRoleDialog] = useState(false)
    const [activeuser, setActiveUser] = useState({ submitter: false, viewer: false, approver: false })
    const [active, setActive] = useState(0)
    const forceUpdate = useForceUpdate()
    useEffect(() => {
        APIServices.get(API.STT_India_User).then((res) => {
            setList1(res.data.data)
            console.log(res.data.data)
        })
        APIServices.get(API.STT_Singapore_User).then((res) => {
            setList2(res.data.data)
        })
        APIServices.get(API.STT_UK_User).then((res) => {
            setList3(res.data.data)
        })
    }, [])

    const countryList = [
        { label: 'India' },
        { label: 'Singapore' },
        { label: 'UK' }

    ];
    const handleSearch1 = (e) => {
        setSearch1(e)
        APIServices.get(API.STT_India_User + '/?search=' + e).then((res) => {
            setList1(res.data.data)

        })
    }
    const onCategoryChange = (obj, val) => {
        let da = activeuser
        da[obj] = val
        setActiveUser(da)
        console.log(da)
        forceUpdate()
    }
    const handleSearch2 = (e) => {
        setSearch2(e)
        APIServices.get(API.STT_Singapore_User + '/?search=' + e).then((res) => {
            setList2(res.data.data)

        })
    }
    const handleSearch3 = (e) => {
        setSearch3(e)
        APIServices.get(API.STT_UK_User + '/?search=' + e).then((res) => {
            setList3(res.data.data)

        })
    }
    const selector = useSelector(state => state.user.userdetail)
    const openUserDialog = (rowData) => {

        APIServices.post(API.STT_Get_User_Role, { email: rowData.mail }).then((res) => {
            console.log(res.data)
            if (res.data === '') {
                setActiveUser({ email: rowData.mail, submitter: false, viewer: false, approver: false });
            } else if (typeof res.data === 'object') {
                setActiveUser(res.data);
            }
            forceUpdate()
        })
        setRoleDialog(true)
    }



    const handleUserCreate = (rowData) => {

        let newObj = { information: { role: {} } }
        newObj["company"] = "ST Telemedia"
        newObj["email"] = rowData.mail;
        newObj.information['empname'] = rowData.displayName;
        newObj.information['emplocation'] = '';
        newObj.information['empdesgination'] = '';
        newObj.information['username'] = rowData.userPrincipalName;

        newObj.information.role['reporter'] = false;
        newObj.information.role['viewer'] = false;
        newObj.information.role['reviewer'] = false;
        newObj.information.role['approver'] = false;

        newObj.information['companyname'] = selector.information.companyname
        newObj.information['companyid'] = selector.userId
        newObj.information['blocked'] = false;
        newObj.information['cid'] = 94
        newObj['role'] = 'clientuser'
        newObj['clientId'] = 94
        if (newObj['clientId'] !== null) {
            APIServices.post(API.NewUserProfile, newObj)
                .then((res) => {

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `User Added successfully`,
                        showConfirmButton: false,
                        timer: 1500
                    })


                }).catch((e) => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: `Email Id exist / check internet connection `,
                        showConfirmButton: false,
                        timer: 1500
                    })

                })
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Something went wrong kindly logout & try again.`,
                showConfirmButton: false,
                timer: 2500
            })
        }


    }




    return (
        <div className="grid p-fluid bg-smoke font-lato" style={{ minHeight: '100vh' }}>
            {selector.role === 'clientadmin' ? <div className="col-12 md:col-12">
                <div className='p-3 card'>
                    <div className='flex fw-7 fs-20 clr-navy justify-content-center'>
                        User Management
                    </div>
                    <div className='flex justify-content-end'>

                        {active === 0 &&
                            <div className='m-1`' style={{ width: 300 }}>
                                <span className="p-input-icon-left" >
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search name" value={search1} onChange={(e) => { handleSearch1(e.target.value) }} />
                                </span>
                            </div>
                        }
                        {active === 1 &&
                            <div className='m-1`' style={{ width: 300 }}>
                                <span className="p-input-icon-left" >
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search name" value={search2} onChange={(e) => { handleSearch2(e.target.value) }} />
                                </span>
                            </div>
                        }
                        {active === 2 &&
                            <div className='m-1`' style={{ width: 300 }}>
                                <span className="p-input-icon-left" >
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search name" value={search3} onChange={(e) => { handleSearch3(e.target.value) }} />
                                </span>
                            </div>
                        }
                    </div>
                    <TabMenu model={countryList} activeIndex={active} onTabChange={(e) => setActive(e.index)} />
                    <div >
                        {active === 0 &&
                            <div>

                                <DataTable value={list1}
                                    scrollable
                                    dataKey="id"
                                    className='fullheight'
                                    emptyMessage="No records found." responsiveLayout="scroll">
                                    <Column header='Name' field='displayName' />
                                    <Column header='Email' field='mail' />
                                    <Column header='Action' body={(rowData) => { return rowData.status === 'active' ? <div className='flex justify-content-start'><i className='pi pi-check'></i></div> : <div className='flex justify-content-start' onClick={() => { handleUserCreate(rowData) }}><i className='pi pi-plus-circle'></i></div> }} />
                                </DataTable>
                            </div>
                        }
                        {active === 1 &&
                            <div>
                                <DataTable value={list2}
                                    scrollable
                                    dataKey="id"
                                    className='fullheight'
                                    emptyMessage="No records found." responsiveLayout="scroll">
                                    <Column header='Name' field='displayName' />
                                    <Column header='Email' field='mail' />
                                    <Column header='Action' body={(rowData) => { return rowData.status === 'active' ? <div className='flex justify-content-start'><i className='pi pi-check'></i></div> : <div className='flex justify-content-start' onClick={() => { handleUserCreate(rowData) }}><i className='pi pi-plus-circle'></i></div> }} />
                                </DataTable>
                            </div>
                        }
                        {active === 2 &&
                            <div>
                                <DataTable value={list3}
                                    scrollable
                                    dataKey="id"
                                    className='fullheight'
                                    emptyMessage="No records found." responsiveLayout="scroll">
                                    <Column header='Name' field='displayName' />
                                    <Column header='Email' field='mail' />
                                    <Column header='Action' body={(rowData) => { return rowData.status === 'active' ? <div className='flex justify-content-start'><i className='pi pi-check'></i></div> : <div className='flex justify-content-start' onClick={() => { handleUserCreate(rowData) }}><i className='pi pi-plus-circle'></i></div> }} />
                                </DataTable>
                            </div>
                        }

                    </div>
                </div>
                <Dialog
                    visible={roledialog}
                    style={{
                        width: "40%",
                    }}
                    header={"Role Assignment"}
                    modal

                    onHide={() => {
                        setRoleDialog(false);
                    }}
                >
                    <div>
                        <div className=" flex justify-content-center">
                            <div className="flex flex-column gap-3">


                                <div className="flex align-items-center">
                                    <Checkbox name="category" value={activeuser.submitter} onChange={(e) => { onCategoryChange('submitter', e.checked) }} checked={activeuser.submitter} />
                                    <label htmlFor={'submitter'} className="ml-2">
                                        Submitter
                                    </label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox name="category_" value={activeuser.approver} onChange={(e) => { onCategoryChange('approver', e.checked) }} checked={activeuser.approver} />
                                    <label htmlFor={'approver'} className="ml-2">
                                        Approver
                                    </label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox name="category__" value={activeuser.viewer} onChange={(e) => { onCategoryChange('viewer', e.checked) }} checked={activeuser.viewer} />
                                    <label htmlFor={'viewer'} className="ml-2">
                                        Viewer
                                    </label>
                                </div>


                            </div>

                        </div>
                        <>
                            <Button
                                label="Close"
                                icon="pi pi-times"
                                className="p-button-text"
                                onClick={() => {
                                    setRoleDialog(false);
                                }}
                            />
                            <Button
                                label="Save & Exit"
                                icon="pi pi-check"
                                className="p-button-text"
                                onClick={() => {
                                    APIServices.post(API.STT_User_Role, activeuser).then((res) => {
                                        setRoleDialog(false)
                                    })
                                }}
                            />
                        </>
                    </div>
                </Dialog>
            </div> :
                <div className='card col-12'>
                    You have no rights to access this page
                </div>
            }
        </div>
    )

}
export default UserListing

