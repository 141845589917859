import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi, resetLoggedUserDetail, setLoggedUserDetail } from "../RTK/Login/userProfile";
import { Divider } from "primereact/divider";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "../../src/Styles/login.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import Axios from "axios";
import { ErrorMessage, Formik, Form, Field } from "formik";
import { Dropdown } from "primereact/dropdown";
import Swal from "sweetalert2";
import { fetchEF } from "../RTK/Emissions/emissionFactor";
import { fetchClientList, fetchUserList } from "../RTK/Background/userProfileList";
import { fetchSiteList } from "../RTK/Background/siteList";
import { fetchEmissionFactor } from "../RTK/Background/emissionFactor";
import { renderMonthlyReport } from "../components/BGHF/helper";
import { resetOverallPendingData, resetPPFData } from "../RTK/Background/pendingData";
import { API } from "../components/constants/api_url";
import { fetchRFLibrary } from "../RTK/Background/RFDCFLibrary";
import { DateTime } from "luxon";
import APIServices from "../service/APIService";
const Login = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [rolelist, setRoleList] = useState([
        {
            name: "Admin",
        },
        {
            name: "User",
        },
    ]);
    const [roleselected, setRoleSelected] = useState({});
    const select = useSelector((state) => state.user.userdetail);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');
        console.log(code, 'code')
        if (code) {
            fetch(`https://${process.env.REACT_APP_AWS_DOMAIN}/oauth2/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&code=${code}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_REDIRECT_URL)}`
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    const access_token = data.access_token;
                    // localStorage.setItem('access_token', access_token);
                    // localStorage.setItem('refresh_token', data.refresh_token)

                    const values = {
                        email: 'shreyasi@eisqr.com',
                        password: 'Ytu5sUZNkN'
                    }

                    handleLogin(values)
                    // dispatch(loginActions.setLogin());
                    // history.push('/dashboard')

                })
                .catch(error => console.error('Error:', error));
        }


    }, [])

    const handleLoginWithAzure = async (e) => {
        e.preventDefault();

        // const newUser = await Auth.federatedSignIn({ provider: 'ADWITHAWS' });
        // console.log(newUser);
        const redirectUrl = `https://${process.env.REACT_APP_AWS_DOMAIN}/oauth2/authorize?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&response_type=code&scope=email+openid+phone&redirect_uri=${encodeURIComponent(process.env.REACT_APP_REDIRECT_URL)}`;
        console.log(redirectUrl)
        window.location.replace(redirectUrl);


    }
    useEffect(() => {
        if (Object.keys(select).length !== 0) {
            if (select.role === "clientuser") {
                if (select.information.blocked === undefined || !select.information.blocked) {
                    dispatch(fetchUserList(select.clientId));
                    dispatch(fetchSiteList(select.clientId))
                    dispatch(fetchEmissionFactor());
                    if (select.information.role.viewer) {
                        props.history.push("/client_user/homescreen");
                    } else {
                        props.history.push("client_user/homescreen");
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Blocked",
                        text: "You have been blocked from platform, contact admin for futher details",
                        returnInputValueOnDeny: () => {
                            console.log("deny");
                        },
                    });
                    // localStorage.clear();
                    // dispatch(resetLoggedUserDetail());
                }
            } else {
                if (select.role === "eisqradmin") {
                    dispatch(fetchClientList());
                    props.history.push("/config/add_new_client");
                } else {
                    // renderMonthlyReport(select.id, dispatch);
                    dispatch(fetchRFLibrary())
                    dispatch(fetchEmissionFactor());
                    dispatch(fetchUserList(select.id));
                    dispatch(fetchSiteList(select.id));
                    props.history.push("/client_home");
                }
            }
        }
    }, [select]);
    useEffect(() => {
        // console.log(DateTime.fromISO("2023-12-09T10:35:37.007Z",{zone:'utc'}).toLocal().toFormat('yyyy LLL dd HH mm'))
        localStorage.clear();
        dispatch(resetPPFData([]));
        dispatch(resetOverallPendingData([]));
        dispatch(resetLoggedUserDetail());

    }, []);

    const handleLogin = (values) => {
        // if (values.email === "admin@eisqr.com" && values.password === "ESJ@e!$Qu@6e") {
        //     let obj = {
        //         email: "admin@eisqr.com",
        //         role: "eisqradmin",
        //         information: {
        //             companyname: "Eisqr",
        //             config: [
        //                 {
        //                     admin: {
        //                         approver: false,
        //                     },
        //                 },
        //             ],
        //         },
        //     };

        //     localStorage.setItem("token", "eisqradmin");
        //     dispatch(setLoggedUserDetail(obj));
        // } else {
        APIServices.post(API.Login, {
            email: values.email.trim(),
            password: values.password.trim(),
        })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem("token", response.data.token);
                    dispatch(fetchApi(response.data.token));
                    // dispatch(setLoggedUserDetail({ name: 'Gopi' }))
                } else {
                    localStorage.removeItem("token");
                }
            })
            .catch((err) => {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: `Invalid Credentials`,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });
        // }
    };

    const handleForgotPassword = (values) => {

        Swal.fire({
            title: "Enter your login mail ID",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Request",
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                return APIServices.post(API.ResetRequest, { email: login })
                    .then((response) => {
                        if (!response.statusText === 'OK') {

                            // throw new Error(response.error.message);
                        } else {
                            Swal.fire('Check your ' + login + ' inbox for reset link')
                        }


                    })
                    .catch((error) => {
                        console.log(error)
                        Swal.showValidationMessage(`Request failed: check given mail id is correct or contact admin if you issue persist `);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        })
    };

    const validationsLogin = yup.object().shape({
        email: yup.string().email("Invalid Email ID").required("Email is mandatory"),
        password: yup.string().min(8, "Minimum 8 character required").required("Password is mandatory"),
        // role: yup
        //     .object().shape({ name: yup.string().required() })
        //     .required("Password is mandatory"),
        // companyid: yup
        //     .string()
        //     .min(1, "Enter Enterprise ID")
        //     .required("Enterprise ID is mandatory"),
    });

    const validationsRegister = yup.object().shape({
        email: yup.string().email("Invalid Email ID").required("Email is mandatory"),
        companyid: yup.string().min(1, "Enter Enterprise ID").required("Enterprise ID is mandatory"),
        password: yup.string().min(8, "Minimum 8 character required").required("Password is mandatory"),
        role: yup
            .object()
            .shape({
                name: yup.string().required(),
            })
            .required("Password is mandatory"),
        confirmation: yup
            .string()
            .oneOf([yup.ref("password"), null], "password not matches")
            .required("Confirmation of Password is required"),
    });

    return (
        <div
            style={{
                height: "100vh",
            }}
        >
            <div className="body">
                <div className="left-login">
                    <img src={require("../assets/images/stt-logo.svg").default} width={"55%"} alt="Pessoas olhando gráficos" />
                    <br />

                    <br />
                    <br />
                    <br />
                    <img src={require("../assets/images/eisqr_logo_final_v1.png").default} width={"65%"} alt="Pessoas olhando gráficos" />
                </div>
                <div className="right-login">
                    <div className="card-login">
                        {/* <div className="user-links">
                                        <div className="user-link-home">
                                            { <Link to="/">Home</Link>}
                                        </div>

                                        <div className="user-link-cad">
                                            { <Link to="/cadastro">Cadastro</Link>}
                                        </div>
                                    </div> */}
                        <h1> LOGIN </h1>
                        <Formik initialValues={{}} onSubmit={handleLogin} validationSchema={validationsLogin}>
                            <Form className="login-form">
                                {/* <div className="form-group">
                                                <label form="email">Enterprise ID</label>

                                                <Field name="companyid" type='text' className="form-field" placeholder="Enterprise Id" />

                                                <ErrorMessage
                                                    component="span"
                                                    name="companyid"
                                                    className="form-error"
                                                />
                                            </div> */}

                                {/*Outro campo*/}

                                {/* <div className="form-group">
                                                <label form="role">Role</label>
                                                <Dropdown style={{ width: '100%' }} value={roleselected} onChange={(e) => { setRoleSelected(e.target.value) }} options={rolelist} optionLabel="name" placeholder="Select" />
                                                <ErrorMessage
                                                    component="span"
                                                    name="role"
                                                    className="form-error"
                                                />
                                            </div> */}


                                <div className="mb-2">
                                    <button onClick={(e) => handleLoginWithAzure(e)} type="button" className={"button"}>
                                        {process.env.REACT_APP_LOGIN_BUTTON_TEXT}
                                    </button>
                                </div>

                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Login;
