
import Axios from "axios"
import moment from "moment"
import { API } from "../constants/api_url"
import { setPPFListData } from "../../RTK/Background/pendingData"
import APIServices from "../../service/APIService"




const renderMonthlyReport = (id, dispatch) => {


    let dcf_list = [], dcf_submitted = [], locloc = [], rawData = []




    const promise1 = APIServices.get(API.DCF)
    const promise2 = APIServices.get(API.DCF_Submit_UP(id))
    const promise3 = APIServices.get(API.LocationThree)


    Promise.all([promise1, promise2, promise3]).then(function (values) {
        dcf_list = values[0].data;

        dcf_submitted = values[1].data;
        locloc = values[2].data

        console.log(values)
        APIServices.get(API.AssignDCFUser_UP(id)).then((res) => {
            let filter = [], locationFilter = [0], pending = [], count = 0

            res.data.forEach((item, i) => {



                if (item.type === 0) {


                    if (dcf_list.filter((k) => { return k.id === item.dcfId }).length !== 0 && locloc.findIndex((k) => { return k.id === item.site[0] }) !== -1) {

                        item.dcf_ = dcf_list.filter((k) => { return k.id === item.dcfId })[0]


                        item.start_date = moment.utc(item.start_date).toDate()
                        filter.push(item)
                        if (locationFilter.findIndex((k) => { return k === item.site[0] }) === -1) {
                            locationFilter.push(item.site[0])
                        }

                        rawData.push(...getMonths(moment(moment.utc(item.start_date).toDate()), moment.utc(moment.utc()), item.frequency, item, dcf_submitted, id).data)


                    }

                }
            })



            dispatch(setPPFListData(rawData))

        })

    })

}
const getMonths = (startDate, endDate, frequency, item, old, id) => {

    var betweenMonths = [];
    let type = 0, past_ = [], present_ = [], future_ = []




    if (startDate.diff(endDate.startOf('month'), 'days') === 0) {
        let endDate_ = startDate

        let endDate__ = moment(moment.utc())
        if (frequency === 1) {

            let check = checkSubmission(item.dcf_, item.site, endDate__.format('MMM-YYYY'), old)

            if (check.result) {

                future_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.add(1, 'month').format('MMM-YYYY'), overdue: 0, site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

            } else {
                if (check.data) {

                    future_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.add(1, 'month').format('MMM-YYYY'), overdue: 0, site: item.site, company_id: id, frequency })
                }

            }
        } else if (frequency === 6) {
            let check = checkSubmission(item.dcf_, item.site, endDate__.format('MMM-YYYY'), old)

            if (check.result) {


                past_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.format('MMM-YYYY'), overdue: endDate__.diff(endDate__, 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
            } else {
                if (check.data) {

                    past_.push({ dcf: item.dcf_, rp: endDate__.format('MMM-YYYY'), duedate: endDate__.format('MMM-YYYY'), overdue: 0, site: item.site, type: 6, company_id: id, frequency })
                }

            }


        }

    }
    else if (startDate.diff(endDate.startOf('month'), 'days') < 31) {
        var date = startDate

        // let endDate__ = moment('01.03.2023', 'DD.MM.YYYY')
        let endDate__ = moment(moment.utc())
        while (date <= endDate__) {

            betweenMonths.push(date.format('MMM-YYYY'));
            date.add(1, 'month');


        }

        if (frequency === 1) {
            console.log(splitArray(betweenMonths, 1), 'SP')
            splitArray(betweenMonths, 1).forEach((months, ind) => {
                console.log(moment(months[0]).month(), item.dcf_, ind)
                if (endDate__.month() === moment(months[0]).month() && endDate__.year() === moment(months[0]).year()) {
                    let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                    if (check.result) {


                        future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                    } else {
                        if (check.data) {
                            future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                        }

                    }


                } else {
                    if (endDate__.diff(moment(months[0]), 'months') === 1) {

                        // if (endDate__ <= moment(months[0]).add(1, 'month').add(4, 'days')) {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                        if (check.result) {


                            present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {
                                present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                            }

                        }


                    } else {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                        if (check.result) {
                            console.log(check.data, '_past', moment(months[0]).format('MMM-YYYY'), item)

                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {

                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                            }

                        }
                    }
                }

            })
        } else if (frequency === 2) {

            splitArray(betweenMonths, 2).forEach((months) => {
                if (months.length === 2) {
                    if (endDate__.month() === moment(months[1]).month() && endDate__.year() === moment(months[1]).year()) {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), old)
                        if (check.result) {

                            future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {
                                future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })


                            }

                        }


                    } else {
                        if (endDate__.diff(moment(months[1]), 'months') === 1) {

                            // if (endDate__ <= moment(months[1]).add(1, 'month').add(4, 'days')) {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), old)
                            if (check.result) {

                                present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            } else {
                                if (check.data) {
                                    present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })


                                }

                            }


                        } else {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), old)

                            if (check.result) {
                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            } else {
                                if (check.data) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[1]).format('MMM-YYYY'), duedate: moment(months[1]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[1]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }
                        }
                    }
                }
            })
        } else if (frequency === 3) {

            splitArray(betweenMonths, 3).forEach((months) => {
                if (months.length === 3) {

                    if (endDate__.month() === moment(months[2]).month() && endDate__.year() === moment(months[2]).year()) {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), old)

                        if (check.result) {

                            future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {
                                future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                            }

                        }


                    } else {
                        if (endDate__.diff(moment(months[2]), 'months') === 1) {

                            // if (endDate__ <= moment(months[2]).add(1, 'month').add(4, 'days')) {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), old)

                            if (check.result) {
                                present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }



                        } else {

                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), old)

                            if (check.result) {
                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[2]).format('MMM-YYYY'), duedate: moment(months[2]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[2]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }
                        }
                    }
                }
            })
        } else if (frequency === 4) {
            splitArray(betweenMonths, 12).forEach((months) => {
                if (months.length === 12) {
                    if (endDate__.month() === moment(months[11]).month() && endDate__.year() === moment(months[11]).year()) {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                        if (check.result) {

                            future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                        } else {
                            if (check.data) {
                                future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                            }

                        }


                    } else {
                        if (endDate__.diff(moment(months[11]), 'months') === 1) {

                            // if (endDate__ <= moment(months[11]).add(1, 'month').add(4, 'days')) {

                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                            if (check.result) {

                                present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            } else {
                                if (check.data) {
                                    present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }

                            // } else {
                            //     let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                            //     if (check.result) {

                            //         past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            //     } else {
                            //         if (check.data) {
                            //             past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                            //         }

                            //     }

                            // }
                        } else {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), old)

                            if (check.result) {

                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            } else {
                                if (check.data) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[11]).format('MMM-YYYY'), duedate: moment(months[11]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[11]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }
                        }
                    }
                }
            })
        } else if (frequency === 5) {
            splitArray(betweenMonths, 6).forEach((months) => {
                if (months.length === 6) {
                    if (endDate__.month() === moment(months[5]).month() && endDate__.year() === moment(months[5]).year()) {
                        let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                        if (check.result) {


                            future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {
                                future_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                            }

                        }


                    } else {
                        if (endDate__.diff(moment(months[5]), 'months') === 1) {

                            // if (endDate__ <= moment(months[5]).add(1, 'month').add(4, 'days')) {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                            if (check.result) {


                                present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }


                        } else {
                            let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                            if (check.result) {


                                past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), site: item.site, company_id: id, frequency })

                                }

                            }
                        }
                    }
                }
            })
        } else if (frequency === 6) {
            splitArray(betweenMonths, 1).forEach((months, ind) => {
                if (moment(months[0]).month() === endDate__.month() && moment(months[0]).year() === endDate__.year()) {
                    let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                    if (check.result) {
                        present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: id, frequency })

                        check.list.forEach((list) => {
                            present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: id, frequency, response: list.response, id: list.id, reject: list.reject, draft: list })

                        })

                    } else {
                        if (check.data) {
                            present_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: id, frequency })

                        }

                    }


                } else {
                    let check = checkSubmission(item.dcf_, item.site, moment(months[0]).format('MMM-YYYY'), old)

                    if (check.result) {

                        check.list.forEach((list) => {
                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: id, frequency, response: list.response, id: list.id })

                        })


                    } else {
                        if (check.data) {
                            past_.push({ dcf: item.dcf_, rp: moment(months[0]).format('MMM-YYYY'), duedate: moment(months[0]).format('MMM-YYYY'), overdue: endDate__.diff(moment(months[0]), 'days'), site: item.site, company_id: id, frequency })

                        }

                    }


                }

            })
        }

    }
    console.log(past_)





    return { type, months: betweenMonths, data: [...past_, ...present_, ...future_] }
}

const checkSubmission = (dcf, site, rp, old) => {
    let rps = getRP(rp)
    let result = {}
    let loc = JSON.parse(JSON.stringify(old))

    let index = loc.findIndex((i) => {
        return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && (i.type === 0 || i.type === 1 || i.type === 3)
    })
    let index2 = loc.findIndex((i) => {
        return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && (i.type === 1 || i.type === 3)
    })

    if (index === -1) {

        result = { result: false, data: true }
    } else {
        result = {
            result: true, data: loc[index], list: loc.filter((i) => {
                return compareArrays(i.reporting_period, rps) && i.site === site[0] && i.dcf === dcf.id && (i.type === 0 || i.type === 1 || i.type === 3)
            })
        }
    }

    return result
}
const compareArrays = (a, b) => {


    return JSON.stringify(a) === JSON.stringify(b);
};
const splitArray = (array, chunkSize) => {


    return array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize)

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, [])
}
const getRP = (months) => {
    if (months.includes('to')) {
        let startDate = moment(months.split('to')[0].trim())
        let endDate = moment(months.split('to')[1].trim())
        let rp = []
        while (startDate <= endDate) {

            rp.push(startDate.format('MM-YYYY'));
            startDate.add(1, 'month');


        }
        return rp
    } else {
        return [moment(months).format('MM-YYYY')]
    }
}
export { renderMonthlyReport }