import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import Swal from "sweetalert2";
import React, { useState, useEffect } from "react";
import APIServices from "../service/APIService";
import { API } from "./constants/api_url";
import { DateTime } from "luxon";
import useForceUpdate from "use-force-update";




export const AttachmentComponent = ({ documents = null, mandatory = false, edit = 0, labels = '', hint = '', getFiles = ()=>{} }) => {

    const [document, setDocument] = useState(documents)
    const forceUpdate = useForceUpdate()
    useEffect(() => {
        getFiles(document)
    }, [document])

    function getFileExtension(filename) {
        const lastDot = filename.lastIndexOf('.');
        return lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
    }
    const handleFileUpload_HC = (e) => {
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.bmp', '.pdf', '.PDF', '.xls', '.xlsx', '.doc', '.docx', '.ppt', '.pptx']

        let filtered_files = Object.values(e.target.files).filter(i => allowedext.includes(i.name.substr(i.name.lastIndexOf('.'))))
        let existingValue = document ? document.reduce((a, b) => { return a + b.size }, 0) : 0
        let formData = new FormData()
        let count = 0
        Object.values(filtered_files).forEach(file => {
            console.log(((file.size + existingValue) <= 10000000), file.size, existingValue)
            if ((file.size + existingValue) <= 10000000) {
                file.extension = file.name.substr(file.name.lastIndexOf('.'))
                formData.append('file', file);

                existingValue += file.size
                count = 1
            }
        })
        e.target.value = null
        if (count) {

            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {

                if (document === undefined || document === null) {
                    setDocument(res.data.files)
                } else {
                    let loc = JSON.parse(JSON.stringify(document))

                    loc.push(...res.data.files)
                    setDocument(loc)


                }
                forceUpdate()
            })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const removeImage_HC = (findex) => {

        document.splice(findex, 1)
        forceUpdate()

    }
    return (
        <div className="mt-3 mb-3">

            {edit === 1 ? <div className="flex flex-wrap  gap-3 grid" style={{ marginBottom: 15, padding: 10 }}>
                <Tooltip target={".ttdoc"} />
                <div className="col-12">
                    <label style={{ display: 'flex' }} className=' fw-5 fs-16 text-justify'>{labels} {hint.length && <span><i style={{
                        fontSize: '18px',
                        marginLeft: '5px'
                    }} className={`material-icons fs-14 ttdoc`} data-pr-tooltip={hint}
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center-2"> help</i></span>}

                    </label>
                    <snall className='flex'>(All type of image, pdf, ppt/pptx, doc/docx, xls/xlsx)</snall>
                </div>
                <div className="col-12" >
                    <div style={{
                        background: '#f8f9fa', justifyContent: 'space-between', display: 'flex', alignItems: 'center',
                        border: '1px solid #ced4da',
                        borderRadius: '6px 6px 0px 0px',
                        padding: '8px'
                    }}>
                        <div>
                            <label htmlFor={'fp'} className="fs-14 clr-navy" style={{
                                marginRight: 10,
                                padding: '5px',
                                background: 'white',
                                border: '1px solid cornflowerblue',
                                borderRadius: '10px',

                            }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-folder-open clr-navy" />
                                Add Attachment
                            </label>
                            <label
                                onClick={() => { setDocument(null) }}
                                style={{
                                    padding: '5px',
                                    fontSize: '15px',
                                    border: '1px solid indianred',
                                    background: 'white',
                                    borderRadius: '10px',
                                    color: 'indianred'
                                }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-undo" />
                                Reset
                            </label>
                        </div>
                        <label>
                            {document ? ((document.reduce((a, b) => { return a + b.size }, 0) / 1000000)).toFixed(2) : 0} Mb / 10 Mb
                        </label>

                        <input type='file' multiple accept="image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint" id={'fp'} hidden onChange={(e) => { handleFileUpload_HC(e) }} ></input>
                    </div>
                    {(document !== undefined && document !== null && document.length) ?
                        <div className="col-12" style={{
                            maxHeight: 300,
                            overflow: 'auto',
                            border: '1px solid #ced4da'
                        }} >
                            <div style={{
                                border: '1px solid #6366F170',
                                borderRadius: '10px'
                            }}>
                                {document.map((file, findex) => {
                                    console.log(getFileExtension(file.originalname))
                                    return (
                                        <>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center', margin: 5
                                            }} >
                                                <div className="flex align-items-center text-three-dot col-10">
                                                    {(getFileExtension(file.originalname) === 'pdf') ?
                                                        <div>
                                                            <iframe src={API.Docs + file.originalname} /> </div> :
                                                        (getFileExtension(file.originalname) === 'xlsx' || getFileExtension(file.originalname) === 'xls') ?
                                                            <img src={require('../assets/images/excel_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                                            (getFileExtension(file.originalname) === 'pptx' || getFileExtension(file.originalname) === 'ppt') ?
                                                                <img src={require('../assets/images/ppt_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                                                (getFileExtension(file.originalname) === 'docx' || getFileExtension(file.originalname) === 'doc') ?
                                                                    <img src={require('../assets/images/word_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                                                    <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                    <span className="flex align-items-center text-left ml-3  clr-navy cur-pointer" onClick={() => { window.open(API.Docs + file.originalname) }}>
                                                        {file.originalname.slice(13)} <Tag className={'status-tag-gray ml-2'} style={{ display: 'block', textDecoration: 'none' }} value={'Updated_on: ' + DateTime.fromISO(file.uploaded_on, { zone: 'utc' }).toLocal().toFormat('dd-LL-yyyy hh:mm a')} />
                                                        {/* <small>{new Date().toLocaleDateString()}</small> */}
                                                    </span>
                                                </div>

                                                <div className="col-1">
                                                    <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage_HC(findex)} />
                                                </div>
                                            </div>

                                        </>
                                    )
                                })

                                }
                            </div>
                        </div>
                        :
                        <div className="col-12 flex justify-content-center align-items-center" style={{
                            height: 100,
                            overflow: 'auto',
                            border: '1px solid #ced4da'
                        }} >
                            No Attachments
                        </div>
                    }
                </div>
            </div> : (document && document.length) ?

                <div className="flex flex-wrap  gap-3 grid" style={{ marginBottom: 15, padding: 10 }}>
                    <div className="col-12" >
                        <div className='col-12'> Attached Documents ( {document.length}) </div>
                        <div className="col-12" style={{
                            maxHeight: 300,
                            overflow: 'auto',
                            border: '1px solid #ced4da'
                        }} >
                            <div style={{
                                border: '1px solid #6366F170',
                                borderRadius: '10px'
                            }}>
                                {document.map((file, findex) => {
                                    console.log(getFileExtension(file.originalname))
                                    return (
                                        <>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center', margin: 5
                                            }} >
                                                <div className="flex align-items-center text-three-dot col-10">
                                                    {(getFileExtension(file.originalname) === 'pdf') ?
                                                        <div>
                                                            <iframe src={API.Docs + file.originalname} /> </div> :
                                                        (getFileExtension(file.originalname) === 'xlsx' || getFileExtension(file.originalname) === 'xls') ?
                                                            <img src={require('../assets/images/excel_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                                            (getFileExtension(file.originalname) === 'pptx' || getFileExtension(file.originalname) === 'ppt') ?
                                                                <img src={require('../assets/images/ppt_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                                                (getFileExtension(file.originalname) === 'docx' || getFileExtension(file.originalname) === 'doc') ?
                                                                    <img src={require('../assets/images/word_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                                                                    <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                    <span className="flex align-items-center text-left ml-3  clr-navy cur-pointer" onClick={() => { window.open(API.Docs + file.originalname) }}>
                                                        {file.originalname.slice(13)} <Tag className={'status-tag-gray ml-2'} style={{ display: 'block', textDecoration: 'none' }} value={'Updated_on: ' + DateTime.fromISO(file.uploaded_on, { zone: 'utc' }).toLocal().toFormat('dd-LL-yyyy hh:mm a')} />
                                                        {/* <small>{new Date().toLocaleDateString()}</small> */}
                                                    </span>
                                                </div>

                                                <div className="col-1">
                                                    <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage_HC(findex)} />
                                                </div>
                                            </div>

                                        </>
                                    )
                                })

                                }
                            </div>
                        </div>

                    </div>
                </div>
                :
                <></>


            }

        </div >
    )
}